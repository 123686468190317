import { Component, OnInit, Renderer2, ViewChild, Input, OnChanges, OnDestroy, ChangeDetectorRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VALIDATION_MESSAGE } from 'app/commonconfig/common-messages';
import { validations } from 'app/module/validation';
import { HttpFrontEndService } from 'app/services/http.frontend.service';
import { LearningHttpService } from 'app/services/learning-http.service';
import { CommonValidationService } from 'app/services/common.validation.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2'

import { Chart } from 'angular-highcharts';
import { debounceTime } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { CommonObservableService } from 'app/services/common-observable.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { commonconfig, swalConfig } from 'app/commonconfig/config';
import { HttpService } from 'app/services/http.service';
import { activity_type } from 'app/module/activity-type';
import { target_section } from 'app/module/targetSection';
import { page_url } from 'app/module/pageUrl';
import SwiperCore, { SwiperOptions, Swiper, Thumbs, Pagination, Navigation, EffectFade, Autoplay } from 'swiper';
SwiperCore.use([Navigation, Pagination, Thumbs, EffectFade, Autoplay]);




@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('swiper1', { static: false }) swiper1?: any;
  @ViewChild('swiper2', { static: false }) swiper2?: any;
  @ViewChild('swiper3', { static: false }) swiper3?: any;
  @ViewChild('accordion') customAccordion: any;
  @Output() sendDataOfIndex = new EventEmitter();


  defaultLogo = `${environment.assetsUrl}images/ea-logo.svg`;
  isEdit: any = false;
  genrateCertificate: any = false;
  userData;
  htmlTag: any;
  containerMain: any;
  heightOutput: any;
  assetURL = environment.assetsUrl;
  submitted = false;
  profileForm: any;
  genrateCertificateForm: any;
  validation = VALIDATION_MESSAGE;
  validations = validations;
  mobilemaxlength = validations.mobilemaxlength;
  showPass = true;
  isShow = false;
  stateData: any = [];
  ProfileData: any = [];
  allQuestion: any = [];
  params: any = {}
  currentActiveTab: any = 'Progress';
  verticalDropdown: any = [];
  coursename: any = ''
  isDisabled = true
  course: any;
  moduleType: any = '0';
  moduledata: any = [];
  serveyData: any = [];
  knowledgeData: any = [];
  ProgressofUser: any = 0;
  bonusProgress: any = 0;
  mandatoryProgress: any = 0;
  countValue: any;
  progressChart: any;
  mandatoryChart: any;
  bonusChart: any;
  satisfactionChart: any;
  precourseChart: any;
  postcourseChart: any;
  knowledgeChart: any;
  Satisfactiondata: any;
  toolMore = false;
  calculatorMore = false;
  certUrl: any;
  userID: any;
  key: any;
  getkey: any;
  LogedInUserData: any
  userassignments: any;
  screenWidh: any;
  currentTab: any = 0;
  course_id: any
  hidearrow = false;
  client_id: any
  classCodeForm: FormGroup
  swalConfig: any = swalConfig;
  commonconfig = commonconfig;
  page_url: any = page_url;

  tool: SwiperOptions = {
    slidesPerView: 'auto',
    simulateTouch: true,
    
    speed: 600,
    
  };
  
  tool1: SwiperOptions = {
    slidesPerView: 5,
    simulateTouch: true,
    loop: false,
    spaceBetween: 20,
    speed: 600,
    breakpoints: {
      1300: { slidesPerView: 5, },
      1199: { slidesPerView: 4, },
      992: { slidesPerView: 3, },
      575: { slidesPerView: 2, },
      200: { slidesPerView: 1, },
    },
  };
  tool2: SwiperOptions = {
    slidesPerView: 6,
    simulateTouch: true,
    loop: false,
    speed: 600,
    breakpoints: {
      1300: { slidesPerView: 6, },
      1199: { slidesPerView: 5, },
      992: { slidesPerView: 4, },
      575: { slidesPerView: 3, },
      200: { slidesPerView: 1, },
    },
  };

  tool3: SwiperOptions = {
    
    simulateTouch: true,
   
    spaceBetween: 20,
    speed: 600,
    breakpoints: {
      1300: { slidesPerView: 5, },
      1199: { slidesPerView: 4, },
      992: { slidesPerView: 3, },
      575: { slidesPerView: 2, },
      200: { slidesPerView: 1, },
    },
  };

  @Input() userPreviewId: any = '';
  constructor(
    private formBuilder: FormBuilder,
    private httpService: HttpFrontEndService,
    private httpAdminService: HttpService,
    private learningHttpService: LearningHttpService,
    public router: Router,
    public activatedRouter: ActivatedRoute,
    private cvs: CommonValidationService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private cos: CommonObservableService,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,

  ) {
    this.classCodeForm = this.formBuilder.group({
      classCodefilter: ['', Validators.required]
    })
    
    this.renderer.listen('window', 'click', (e: Event) => {
      const el: any = e?.target;
      this.hidePasswordMessage(el.id);
    });
    this.htmlTag = document.getElementsByTagName('html');

    if (this.htmlTag) {
      this.htmlTag[0].classList.add('ea-zoom-enabled');
      this.heightOutput = `${this.htmlTag[0].offsetHeight - 116}px`
    }

  }

  ngOnDestroy() {
    this.getCourseDataOfUserSubscription?.unsubscribe();
    this.htmlTag[0].classList.remove('ea-zoom-enabled');
    this.cos.activeTab.next('');
  }

  async ngOnInit() {

    let h = document.body.clientHeight;

    this.cos.useriddata.subscribe(res => {
      this.userID = res;

    });


    this.cos.activeTab.subscribe(res => {
      if (res) { this.currentActiveTab = res['activeTab']; }
    });

    let completeData: any = await this.learningHttpService.post('/api/getCompleteDataOfUser', { user_id: this.userID.id, role_id: 1 }).toPromise();
    this.course_id = completeData?.data[0]?.course_id;
    this.client_id = completeData?.data[0]?.client_id

    this.createClassCodeForm()
    this.form();
    this.params = { user_id: this.userID.id, course_id: this.course };
    this.getvertical();
    this.genForm();
    this.getProfileData();
    this.getStateList();
    this.getCertificateStatus();
    this.getEssentialTiles();
    this.getUserProfileProgress();
    
    this.profileForm.controls['user_name'].valueChanges
      .pipe(debounceTime(validations.debounceSearchTime)).subscribe((value: any) => {
        if (value) this.isUsernameExist(value);
      });

    this.profileForm.controls['alternate_email'].valueChanges
      .pipe(debounceTime(validations.debounceSearchTime)).subscribe((value: any) => {
        if (value) this.isalredyEmailExist(value);
      });

    this.profileForm.controls['email'].valueChanges
      .pipe(debounceTime(validations.debounceSearchTime)).subscribe((value: any) => {
        if (value) this.isEmailExist(value);
      });

    this.htmlTag = document.getElementsByTagName('html');
    this.heightOutput = `${this.htmlTag[0].offsetHeight - 116}px`;
    this.containerMain = document.getElementById('myDivId');
    if (this.containerMain) if (this.containerMain.style) this.containerMain.style.height = this.heightOutput;
    window.addEventListener("resize", this.resizeListener);

  }
  resizeListener() {
    this.htmlTag = document.getElementsByTagName('html');
    this.heightOutput = `${this.htmlTag[0].offsetHeight - 116}px`;
    this.containerMain = document.getElementById('myDivId');
    if (this.containerMain) if (this.containerMain.style) this.containerMain.style.height = this.heightOutput
  }

  onResize() {
  }

  ngAfterViewInit() {}


  private simulateResizeEvent() {
    const event = new Event('resize');
    this.renderer.listen('window', 'resize', () => window.dispatchEvent(event));
  }


  accordionActive = 0;
  accordion(id: any) {
    if (this.accordionActive == id) this.accordionActive = 0;
    else this.accordionActive = id;
  }
  
  courseProgressChart() {
    const progress: any = new Chart({
      chart: {
        plotBackgroundColor: '',
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: {
        text: `<strong>${this.ProgressofUser}%</strong>`,
        align: 'center',
        verticalAlign: 'middle',
        style: {
          fontSize: '30px',
        }
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.0f}%</b>'
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: '500',
              fontSize: '12px',
              color: ''
            }
          },
          startAngle: -90,
          endAngle: -180,
          center: ['50%', '50%'],
          size: '100%',
          showInLegend: false
        }
      },
      series: [
        {
          name: '',
          data: [
            {
              name: '',
              y: this.ProgressofUser,
              color: '#458cf4'
            },
            {
              name: '',
              y: (100 - this.ProgressofUser),
              color: '#d6d6d6'
            }
          ],
          type: 'pie',
          innerSize: '75%',
        }]
    });

    const mandatory: any = new Chart({
      chart: {
        plotBackgroundColor: '',
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: {
        text: `<strong>${this.mandatoryProgress}%</strong>`,
        align: 'center',
        verticalAlign: 'middle',
        style: {
          fontSize: '22px',
        }
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.0f}%</b>'

      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: '',
              color: ''
            }
          },
          startAngle: -90,
          endAngle: -180,
          center: ['50%', '50%'],
          size: '100%',
          showInLegend: false
        }
      },
      series: [
        {
          name: '',
          data: [
            {
              name: '',
              y: this.mandatoryProgress,
              color: '#56c461'
            },
            {
              name: '',
              y: (100 - this.mandatoryProgress),
              color: '#d6d6d6'
            }
          ],
          type: 'pie',
          innerSize: '75%',
        }]
    });
    const bonus: any = new Chart({
      chart: {
        plotBackgroundColor: '',
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: {
        text: `<strong>${this.bonusProgress}%</strong>`,
        align: 'center',
        verticalAlign: 'middle',
        style: {
          fontSize: '22px',
        }
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.0f}%</b>'
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: '',
              color: ''
            }
          },
          startAngle: -90,
          endAngle: -180,
          center: ['50%', '50%'],
          size: '100%',
          showInLegend: false
        }
      },
      series: [
        {
          name: '',
          data: [
            {
              name: '',
              y: this.bonusProgress,
              color: '#56c461'
            },
            {
              name: '',
              y: (100 - this.bonusProgress),
              color: '#d6d6d6'
            }
          ],
          type: 'pie',
          innerSize: '75%',
        }]
    });
    this.bonusChart = bonus;
    this.progressChart = progress;
    this.mandatoryChart = mandatory;
  }
  satisfactionChartFunc() {
    const satisfaction: any = new Chart({
      chart: {
        plotBackgroundColor: '',
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: {
        useHTML: true,
        text: `<img style="width:35px;height:35px" src="${environment.assetsUrl}/images/avtar/satisfaction-score-icon.svg">`,
        align: 'center',
        verticalAlign: 'middle',
        style: {
          fontSize: '20px'
        }
      },
      tooltip: {
        pointFormat: '<b>{point.percentage}%</b>'
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: '',
              color: ''
            }
          },
          startAngle: -90,
          endAngle: -180,
          center: ['50%', '50%'],
          size: '150%',
          showInLegend: false
        }
      },
      series: [
        {
          name: '',
          data: [
            {
              name: '',
              y: this.Satisfactiondata?.rating?.rating * 10,
              color: '#9FBA3A'
            },
            {
              name: '',
              y: (100 - (this.Satisfactiondata?.rating?.rating * 10)),
              color: '#C7C7C7'
            }
          ],
          type: 'pie',
          innerSize: '74%',
        }]
    });
    this.satisfactionChart = satisfaction;

  }
  knowledgeLevelChart() {
    const knowledge: any = new Chart({
      chart: {
        plotBackgroundColor: '',
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: {
        useHTML: true,

        text: `<img style="width:35px;height:35px" src="${environment.assetsUrl}/images/knowledge-icon.svg">`,

        align: 'center',
        verticalAlign: 'middle',
        style: {
          fontSize: '12px'
        }
      },
      tooltip: { enabled: false },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: '',
              color: ''
            }
          },
          startAngle: -90,
          endAngle: -180,
          center: ['50%', '50%'],
          size: '100%',
          showInLegend: false
        },
      },
      exporting: {
        enabled: true
      },
      series: [
        {

          name: '',
          data: [
            {
              name: '',
              y: Number(100),

              color: '#2693D5'
            },
          ],
          type: 'pie',
          innerSize: '70%',
        }]
    });
    const preCourse: any = new Chart({
      chart: {
        plotBackgroundColor: '',
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: {
        useHTML: true,

        text: `<img style="width:35px;height:35px" src="${environment.assetsUrl}/images/Path_3456.svg">`,
        align: 'center',
        verticalAlign: 'middle',
        style: {
          fontSize: '12px'
        }
      },
      tooltip: {
        pointFormat: '<b>{point.y}/{point.x}</b>'

      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: '',
              color: ''
            }
          },
          startAngle: -90,
          endAngle: -180,
          center: ['50%', '50%'],
          size: '100%',
          showInLegend: false
        }
      },
      series: [
        {
          name: '',
          data: [
            {
              name: '',
              y: this.knowledgeData?.count?.precount?.selfassismentcount,
              x: this.knowledgeData?.question?.selfquestion?.length,
              color: '#2693D5'
            },
            {
              name: '',
              y: this.knowledgeData?.count?.precount?.factualbasecount,
              x: this.knowledgeData?.question?.factualquestion?.length,
              color: '#45C6A8'
            },
            {
              name: '',
              y: (this.allQuestion?.length - (this.knowledgeData?.count?.precount?.factualbasecount + this.knowledgeData?.count?.precount?.selfassismentcount)),
              x: this.allQuestion?.length,
              color: '#C7C7C7'
            }
          ],
          type: 'pie',
          innerSize: '70%',
        }]
    });

    const postCourse: any = new Chart({
      chart: {
        plotBackgroundColor: '',
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: {
        useHTML: true,

        text: `<img style="width:35px;height:35px" src="${environment.assetsUrl}/images/Path_3455.svg">`,
        align: 'center',
        verticalAlign: 'middle',
        style: {
          fontSize: '12px'
        }
      },
      tooltip: {
        pointFormat: '<b>{point.y}/{point.x}</b>'

      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: '',
              color: ''
            }
          },
          startAngle: -90,
          endAngle: -180,
          center: ['50%', '50%'],
          size: '100%',
          showInLegend: false
        }
      },
      series: [
        {
          name: '',
          data: [

            {
              name: '',
              y: this.knowledgeData?.count?.postcount?.selfassismentcount,
              x: this.knowledgeData?.question?.selfquestion?.length,
              color: '#2693D5'
            },
            {
              name: '',
              y: this.knowledgeData?.count?.postcount?.factualbasecount,
              x: this.knowledgeData?.question?.factualquestion?.length,
              color: '#45C6A8'
            },
            {
              name: '',
              y: (this.allQuestion?.length - (this.knowledgeData?.count?.postcount?.factualbasecount + this.knowledgeData?.count?.postcount?.selfassismentcount)),
              x: this.allQuestion?.length,
              color: '#C7C7C7'
            }
          ],
          type: 'pie',
          innerSize: '70%',
        }]
    });
    this.precourseChart = preCourse;
    this.postcourseChart = postCourse;
    this.knowledgeChart = knowledge;
  }


  get getPassword() { return this.profileForm?.get('password'); }

  get setPassInValidClass() { return this.submitted && this.profileForm.controls.password.errors && (this.profileForm.controls.password.errors.required || this.profileForm.controls.password.errors.weak) }

  form() {
    this.profileForm = this.formBuilder.group({
      user_name: ['', Validators.required],
      email: ['', [Validators.pattern(validations.emailFormat)]],
      alternate_email: ['', [Validators.pattern(validations.emailFormat)]],
      make_primary: [''],
      first_name: ['', Validators.required],
      last_name: [''],
      state: [null, Validators.required],
      city: ['', Validators.required],
      phone: [''],
      password: ['', this.cvs.passwordStrength()],
      default_language: [1]
    });
  }

  onSelect(e: any) {    
    try {
      this.currentActiveTab = e;
      if (e == 'taskprogress') {
        setTimeout(() => {
          const swiper2 = new Swiper('.swiper-container-2', {
            ...this.tool2,
            navigation: {
              nextEl: ".ld-navs-button--right2",
              prevEl: ".ld-navs-button--left2",
            },
          });
          setTimeout(() => {
            swiper2.update();
          }, 10);
          document.getElementById('swiper-container-2').classList.remove('display-none')
          this.cdr.detectChanges();
          swiper2.updateSize();
          swiper2.update();
          swiper2.updateSlides();
          this.Select('', 0, this.allModuleData[0]);
        }, 10);
      }else if(e == 'Progress'){
        console.log('Progress');        
        setTimeout(() => {
          const swiper1 = new Swiper('.swiper-container-1', {
            ...this.tool1,
            navigation: {
              nextEl: ".ld-navs-button--left1",
              prevEl: ".ld-navs-button--right1",
            },
          });
          setTimeout(() => {        
            swiper1.update();
          }, 10);
          document.getElementById('swiper-container-1').classList.remove('display-none')
          this.cdr.detectChanges();
          swiper1.updateSize();
          swiper1.update();    
          this.cdr.detectChanges();
        }, 10);
      }
      this.changeslideclass();
    } catch (error) {
      console.log(error);
    }
  }

  setDefaultProgressOfUser() {
    this.ProgressofUser = 0;
    this.bonusProgress = 0;
    this.mandatoryProgress = 0;
  }


  essentialTilesData: any
  async getEssentialTiles() {
    try {
      this.params.course_id = this.course_id;
      this.params.client_id = this.userID.id;
      let res = await this.learningHttpService.post('/api/user/getEssentialTiles', this.params).toPromise()
      if (res.status) {
        this.essentialTilesData = res.data.TilesData;

        for (let i = 0; i < this.essentialTilesData.length; i++) {
          this.essentialTilesData[i].file_title = this.sanitizer.bypassSecurityTrustResourceUrl(this.assetURL + this.essentialTilesData[i].file_composer + '/' + this.essentialTilesData[i].file_title);
        }
      }
    } catch (error) { }
  }


  bonusVideos = 0
  async getOverallProgressOfUser() {
    try {
      let res: any = await this.learningHttpService.post('/api/user/getOverallProgressOfUser', this.params).toPromise();
      if (res.status) {
        this.ProgressofUser = res.data.overAllProgress ? res.data.overAllProgress : 0;
        this.bonusProgress = res.data.bonusProgress ? res.data.bonusProgress : 0;
        this.mandatoryProgress = res.data.regularProgress ? res.data.regularProgress : 0;
        this.bonusVideos = res.data.bonusVideos
        this.courseProgressChart();
      }
      else {
        this.ProgressofUser = 0;
        this.bonusProgress = 0;
        this.mandatoryProgress = 0;
        this.courseProgressChart();
      }
    } catch (error) {
      this.ProgressofUser = 0;
      this.bonusProgress = 0;
      this.mandatoryProgress = 0;
      this.courseProgressChart();
    }
  }

  ageGroup: any = [];
  getvertical() {
    this.learningHttpService.post('/api/getverticalforuser', { user_id: this.userID.id, client_id: this.client_id }).subscribe(res => {
      if (res.status) {
        this.verticalDropdown = res?.data;
        this.ageGroup = res?.['ageGroup'];
        if (this.verticalDropdown.length == 1) { this.hidearrow = true }

        let default_course = res.data?.filter((e: any) => e.is_default == 1)[0];
        this.params.course_id = default_course?.id

        this.classCodeForm.patchValue({ classCodefilter: default_course.class })
        this.course = this.params.course_id;

        let selectedCourse = this.verticalDropdown?.filter((e: any) => e.id == this.course)[0];
        this.coursename = selectedCourse?.name;
        this.checkAgeGroupType(selectedCourse);
        this.getOverallProgressOfUser();
        this.getCourseData()
        this.getUserProgress();
        this.getDownloadData();
        this.getknowledgeData();
        this.getSatisfactionData();
      }
    })
  }

  vertical(e: any) {
try {
  

    this.moduleType = 0;
    this.course = e.id;
    this.params.course_id = e.id;

    let selectedCourse = this.verticalDropdown?.filter((e: any) => e.id == this.course)[0];
    this.coursename = selectedCourse.name;
    this.checkAgeGroupType(selectedCourse);
    this.getOverallProgressOfUser();
    this.getDownloadData();
    this.getUserProgress();
    this.getCourseData();
    this.getknowledgeData();
    this.getSatisfactionData();

  } catch (error) {
    console.log(error);
  }
  }

  coursesData(e: any) {
    document.getElementById('swiper-container-1').classList.add('display-none')

    setTimeout(() => {
      const swiper1= new Swiper('.swiper-container-1', {
         ...this.tool1,
         navigation: {
           nextEl: ".ld-navs-button--left1",
           prevEl: ".ld-navs-button--right1",
         },
       });
       setTimeout(() => {        
         swiper1.update();
         swiper1.updateSize();
         swiper1.update(); 
         setTimeout(() => {
           document.getElementById('swiper-container-1').classList.remove('display-none')
          }, 10);
        }, 0);
        this.cdr.detectChanges();
      }, 0);
      this.moduleType = e.target.value;
      this.moduledata = this.allModuleData.filter((m: any) => m.module_type == this.moduleType)

  }

  isBonusExists: boolean = false;
  allModuleData: any = [];
  getCourseDataOfUserSubscription: any;

  checkAgeGroupType(selectedCourse: any) {

    let arr: any = [];
    let localAgeGroup = this.ageGroup;

    let selectedAgeGroupArr = selectedCourse?.age_group?.split(',');
    selectedAgeGroupArr.forEach((item: any) => {
      let singleGroup = localAgeGroup.find((ev: any) => ev.id == item)
      if (singleGroup) arr.push(singleGroup);
    })
    const ageArr = arr.map((el: any) => el.age_from);
    const getMaxAge = Math.max(...ageArr);
    if (getMaxAge >= 18) {
      this.key = 'adult';
    } else {
      localStorage.setItem('ageGroup', btoa('kids'));
      this.key = 'kids';
    }
  }

  getDownloadData() {
    this.learningHttpService.post('/api/getDownloadabledataOfUser', { user_id: this.userID.id, course_id: this.course }).subscribe(res => {
      if (res.status) {
        this.countValue = res.data
        this.countValue.tool = this.countValue.tool.filter((el: any) => { return el.file_for == 0 });
      }

    })
  }


 
  tofixeddata(i: any) { return Number(i).toFixed(2) }

  getknowledgeData() {
    this.learningHttpService.post('/api/getKnowledgeDataOfUserforquestion', { course_id: this.course, user_id: this.userID.id }).subscribe(res => {
      if (res.status) {
        this.knowledgeData = res.data;
        this.allQuestion = Object.values([...this.knowledgeData?.question.factualquestion, ...this.knowledgeData?.question.selfquestion].reduce((r, o) => (r[o.sequence] = o, r), {}));
      }
      this.knowledgeLevelChart();


    })
  }

  
  currentModule:any

  async getCourseData() {
    try {
      this.moduledata = [];
    this.AssismentProgress = 'Loading'
    this.isBonusExists = false;
    let class_code = this.userID.class_code;
    this.getCourseDataOfUserSubscription?.unsubscribe();
    this.getCourseDataOfUserSubscription = this.learningHttpService.post('/api/getCourseDataOfUserUpdated', { course_id: this.course, module_type: this.moduleType, user_id: this.userID.id, class_code: class_code, key: this.key, client_id: this.client_id }).subscribe(async (res: any) => {
      if (res.status) {
        this.moduledata = res.data?.defaultCourseData;        
        this.allModuleData = res.data?.defaultCourseData;
        this.AssismentProgress = res.data?.assignPercentage;
        this.currentModule =res.data?.currentModule[0]?.current_module_id
        for (let j = 0; j < this.moduledata.length; j++) {
          if (this.moduledata[j].module_type == 1) {
            this.isBonusExists = true;
          }
        }
        this.coursesData({ target: { value: 0 } });
        this.screenWidh = window.innerWidth;
        if (window.innerWidth >= 1300 && this.userassignments?.length <= 4) {
          document.getElementById('assignmentSwiperWidth')?.classList.add('ea-swiper-content--center');

        }
        else if (window.innerWidth >= 1199 && this.userassignments?.length <= 3) {
          document.getElementById('assignmentSwiperWidth')?.classList.add('ea-swiper-content--center');
        }
        else if (window.innerWidth >= 992 && this.userassignments?.length <= 2) {
          document.getElementById('assignmentSwiperWidth')?.classList.add('ea-swiper-content--center');
        }
        else if (window.innerWidth >= 575 && this.userassignments?.length <= 1) {
          document.getElementById('assignmentSwiperWidth')?.classList.add('ea-swiper-content--center');
        }
        setTimeout(() => {
         const swiper1= new Swiper('.swiper-container-1', {
            ...this.tool1,
            navigation: {
              nextEl: ".ld-navs-button--left1",
              prevEl: ".ld-navs-button--right1",
            },
          });
          setTimeout(() => {        
            swiper1.update();
            swiper1.updateSize();
          swiper1.update(); 
          }, 100);
          document.getElementById('swiper-container-1').classList.remove('display-none')
          this.cdr.detectChanges();
        }, 100);
      }
    })

  } catch (error) {
    console.log(error);    
  }
  }

  getSatisfactionData() {
    this.learningHttpService.post('/api/getSatisfactionScoreDataOfUser', { course_id: this.course, user_id: this.userID.id }).subscribe(res => {
      if (res.status) {
        this.Satisfactiondata = res.data
      }
      this.satisfactionChartFunc();
    })
  }

  genForm() {
    this.genrateCertificateForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(validations.emailFormat)]],
    })
  }
  classCodefilter: FormControl
  createClassCodeForm() {


 
  }

  isalredyEmailExist(email: string) {
    if (this.ProfileData?.alternate_email !== email && this.ProfileData?.email !== email) {
      this.learningHttpService.post('/api/isEmailExist', { email }).subscribe((res) => {
        if (res.status) { this.profileForm?.controls.alternate_email.setErrors({ 'isEmailExist': true }); }
      });
    }
  }

  isEmailExist(email: string) {
    if (this.ProfileData?.email !== email) {
      this.learningHttpService.post('/api/isEmailExist', { email }).subscribe((res) => {
        if (res.status) { this.profileForm?.controls.email.setErrors({ 'isEmailExist': true }); }
      });
    }
  }

  isUsernameExist(username: string) {
    if (this.ProfileData?.user_name !== username) {
      this.learningHttpService.post('/api/isUserNameExist', { username }).subscribe((res) => {
        if (res.status) { this.profileForm?.controls.user_name.setErrors({ 'isUsernameExist': true }); }
      });
    }
  }

  showHidePass() {
    if (this.showPass) this.showPass = false;
    else this.showPass = true;
  }
  hidePasswordMessage(id?: any) {
    if (id === "createPassword") this.getPassword?.markAsTouched();
    if (id != "createPassword" && this.getPassword?.errors && (this.getPassword?.errors['strong'] || this.getPassword.errors['medium'])) {
      this.getPassword?.setErrors(null);
      this.isShow = false;
    } else { this.isShow = true; }
  }

  createPassword() { this.getPassword?.patchValue(this.cvs.createPasswordString()); }

  OnSubmit() {
    this.submitted = true;
    if (this.profileForm.invalid) return;
    this.submitted = false;
    
    let data = { ...this.profileForm.value }
    data.state = data.state.id;
    data.user_id = this.ProfileData.id;

    let keys = Object.keys(data);
    for (let x of keys) { if (!data[x]) delete data[x]; };
    if (!data.last_name) data.last_name = this.profileForm.value.last_name;
    if (data.make_primary == true && data.alternate_email != "") {
        data.primary_email = data.alternate_email
        this.learningHttpService.post('/api/user/UpdateProfileData', data).subscribe(res => {
          if (res.status) {
            this.toastr.success(res.message, '');
            this.form();
            this.getProfileData();
            this.isEdit = false;
          }else{this.toastr.error(res.message, '');}
        });
     
    }
    else if (data.make_primary == true && data.alternate_email == "") {
      Swal.fire({
        title: 'Warning!',
        text: 'Please make sure your Alternate email is not empty.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#E42728',
        cancelButtonColor: '#7367F0',
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1'

        }
      }).then(async (result) => {
        if (result.value) {
          this.profileForm.patchValue({
            alternate_email: this.ProfileData?.alternate_email,
          })
        }
      })
    }
    else {
      data.primary_email = data.email
      this.learningHttpService.post('/api/user/UpdateProfileData', data).subscribe(res => {
        if (res.status) {
          this.toastr.success(res.message, '');
          this.form();
          this.getProfileData();
          this.isEdit = false;
        }else{this.toastr.error(res.message, '');}
      });
    }

  }


  
  getProfileData() {
    
    this.learningHttpService.post('/api/getCompleteDataOfUser', { user_id: this.userID.id, role_id: this.userID.role_Id }).subscribe(res => {
      if (res.status) this.ProfileData = res.data[0];
      let data = [];
      this.ProfileData.access_code = this.ProfileData.class_code.filter((el: any) => el.is_default == 1)[0].client_class_code
      for (let i = 0; i < this.ProfileData.AgeGroupData?.length; i++) {
        data.push(this.ProfileData.AgeGroupData[i].age_from)
        data.sort();
        if (data[i] >= 18) {
          this.key = 'adult'
        } else {
          this.key = 'kids';
        }
      }
    });
  }


  onSend() {
    this.submitted = true;
    if (this.mandatoryProgress != 100) {
      this.toastr.warning('Complete the Course to get the Certificate');
      return
    }
    this.submitted = false;
    let data = {
      user_id: this.userID.id,
      course_id: this.course,
      generated_by: 0
    };
    this.learningHttpService.post('/learning/getCourseCertificate', data).subscribe(res => {
      if (res.status) {
        this.certUrl = res.data.certificate;
        let data1: any = {};
        data1['certificate'] = this.certUrl;
        data1['name'] = this.ProfileData?.display_name;
        data1['email'] = this.genrateCertificateForm.value.email;
        this.learningHttpService.post('/api/certificateMailSend', data1).subscribe(res => {
          if (res.status) {
            this.toastr.success('Certificate has been Sent to your email');
          }
        })

      }
    })

  }

  editProfile() {
    this.isEdit = true;
    this.genrateCertificate = false;
    let state = this.stateData.filter((el: any) => el.id == this.ProfileData.state);
    this.profileForm.patchValue({
      user_name: this.ProfileData?.user_name,
      first_name: this.ProfileData?.first_name,
      last_name: this.ProfileData?.last_name,
      email: this.ProfileData?.email,
      make_primary: this.ProfileData?.alternate_email == this.ProfileData?.primary_email ? true : false,
      alternate_email: this.ProfileData?.alternate_email,
      state: state[0],
      city: this.ProfileData?.city,
      phone: this.ProfileData?.phone,
      default_language: this.ProfileData?.default_language,
    });
  }

  genCertificate() {
    this.isEdit = false;
    this.genrateCertificate = true;
    this.genrateCertificateForm.patchValue({ email: this.ProfileData?.email })
  }

  cancel() {
    this.form();
    this.genForm();
    this.isEdit = false;
    this.genrateCertificate = false;
  }

  getStateList() {
    this.learningHttpService.post('/api/GetStateData', { id: 38 }).subscribe(res => {
      if (res.status) this.stateData = res.data.state;
    });
  }


  ShowPopup(e: any) {
    Swal.fire({
      title: 'Warning!',
      text: 'Do you want to make this as secondary email address? Your earlier primary email will be used for further communication.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E42728',
      cancelButtonColor: '#7367F0',
      confirmButtonText: 'Ok',
      customClass: {
        popup: 'email-swal--popup',
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary ml-1'

      }
    }).then(async (result) => {
      if (result.value) {
        this.profileForm.patchValue({
          make_primary: e.target.checked
        })
      } else {
        this.profileForm.patchValue({
          make_primary: !e.target.checked,
        })
      }
    })
  }

  Select(e: any, index: any, data: any) {
    try {
      this.userassignments = data.AssignmentData
      this.currentTab = index;
      this.changeslideclass();
      setTimeout(() => {
        const swiper3 = new Swiper('.swiper-container-3', {
          ...this.tool3,
          navigation: {
            nextEl: ".ld-navs-button--right3",
            prevEl: ".ld-navs-button--left3",
          },
        });
        setTimeout(() => {
          swiper3.update();
        }, 10);
      document.getElementById('swiper-container-3').classList.remove('display-none')

        this.cdr.detectChanges();
        swiper3.update();
      }, 10);
    } catch (error) {
      console.log(error);
    }
  }

  mymodule: any
  AssismentProgress: any = 0;


  async changeslideclass() {

    if (window.innerWidth >= 1300 && this.userassignments?.length > 4) {
      document.getElementById('assignmentSwiperWidth')?.classList.remove('ea-swiper-content--center');
    }
    else if (window.innerWidth >= 1199 && this.userassignments?.length > 3) {
      document.getElementById('assignmentSwiperWidth')?.classList.remove('ea-swiper-content--center');
    }
    else if (window.innerWidth >= 992 && this.userassignments?.length > 2) {
      document.getElementById('assignmentSwiperWidth')?.classList.remove('ea-swiper-content--center');
    }
    else if (window.innerWidth >= 575 && this.userassignments?.length > 1) {
      document.getElementById('assignmentSwiperWidth')?.classList.remove('ea-swiper-content--center');
    }
    if (window.innerWidth >= 1300 && this.userassignments?.length <= 4) {
      document.getElementById('assignmentSwiperWidth')?.classList.add('ea-swiper-content--center');
    }
    else if (window.innerWidth >= 1199 && this.userassignments?.length <= 3) {
      document.getElementById('assignmentSwiperWidth')?.classList.add('ea-swiper-content--center');
    }
    else if (window.innerWidth >= 992 && this.userassignments?.length <= 2) {
      document.getElementById('assignmentSwiperWidth')?.classList.add('ea-swiper-content--center');
    }
    else if (window.innerWidth >= 575 && this.userassignments?.length <= 1) {
      document.getElementById('assignmentSwiperWidth')?.classList.add('ea-swiper-content--center');
    }
  }
  certificateStatus: any = 0;
  async getCertificateStatus() {
    let data = await this.learningHttpService.post('/learning/getSurveyPostCertificateStatus', { user_id: this.userID.id, course_id: this.course_id }).toPromise();
    if (data?.status) {
      if (data?.data?.certificateStatus?.[0]) {
        this.certificateStatus = data?.data?.certificateStatus?.[0]?.totalRow;
      }
    }
  }


  checkGenerateCertificate() {
    if (this.mandatoryProgress != 100) {
      return
    } else {
      this.isEdit = false
      this.genrateCertificate = true;
    }
  }
  certificate_status: any = 0

  getUserProgress() {

    this.learningHttpService.post('/api/getuserprogress', { user_id: this.userID.id, course_id: this.course }).subscribe((res: any) => {

      if (res.status) {



        if (!res['data'][0].regular_progress) {
          this.certificate_status = 1
        }
        if (res['data'][0].regular_progress == 100) {
          if (res['data'][0].certificate_status == 0) {
            this.certificate_status = 2//available
          } else {
            this.certificate_status = 3//downloaded
          }
        } else {
          this.certificate_status = 1//pending
        }

      }
    })
  }
  completion_percentage: any = '0.00%'

  async getUserProfileProgress() {


    this.learningHttpService.post('/api/getUserProfileProgress', { user_id: this.userID.id }).subscribe((result: any) => {

      this.completion_percentage = (result['data'][0].completion_percentage).toFixed() + '%'
    });

  }
  saveLogs(type: string, pageURl: string, targetSection: string, data: any) {
    this.httpAdminService.saveActivityLog(type, pageURl, targetSection, data)

  }
  changeStatus(ProfileData){
    this.swalConfig.text = commonconfig.sweltextuserstatus.replace('{{text}}', ProfileData.display_name ? ProfileData.display_name.trim() : '');
    this.swalConfig.confirmButtonText = commonconfig.swalconfirmtext01;
    Swal.fire(this.swalConfig).then(async (result) => {
      if (result.value) {
        try {
          var rowdata = ProfileData;
          if (ProfileData.status == 1) ProfileData.status = 0;
          else ProfileData.status = 1;
          ProfileData.modified = new Date();
          const { modified, status, user_id }: any = { ...ProfileData };
          
          this.httpAdminService.post('userChangeStatusAndDelete', { user_id: user_id, modified, status }).subscribe((res) => {
            if (res.status) {
              this.toastr.success(res.message, '');
              if (ProfileData?.active_campaign_status == 1 && ProfileData?.client_status == 1 && ProfileData?.client_type == 1) {
                this.httpAdminService.deleteDataInActiveCampaign(ProfileData?.email, ProfileData.status)
              }
              this.saveLogs(activity_type.user_management_status_clicked, this.page_url.Manage_Users, target_section.status_user_management, rowdata);
            }
            else { this.toastr.error(res.message, ''); }
            
          })
        } catch (e) { this.toastr.warning('', environment.serverErrorMessage); }
      }
    });

  }
}