export const page_url = {

    "Manage_comments" : { url: "post-management/post-comments",  description: "viewed Manage comments page." },
    "Manage_Blogs": { url: "post-management/manage-posts",  description: "viewed Manage blog page." },
    "Manage_Blogs_Add_Update": { url: "post-management/manage-add-posts",  description: "viewed Manage Add blog page." },
    "Manage_Companies": { url: "user-management/manage-companies",  description: "viewed Manage companies page." },
    "Email_Template": { url: "email-template",  description: "viewed email template page." },
    "Manage_Users": {url: "user-management/manage-users",  description: "viewed users management page."},
    "Manage_Client_Admin": {url: "user-management/manage-client-admins",  description: "viewed Management client admin page."},
    "Manage_Client_Temporary": {url: "user-management/manage-client-temporary",  description: "viewed Management client temporary page."},
    "Manage_Client": {url: "user-management/manage-client",  description: "viewed Management client page."},
    "Client_Bulk_Upload": {url: "user-management/manage-client",  description: "Upload client bulk file."},
    "Manage_Company_Admin": {url: "user-management/manage-company-admins",  description: "viewed Management company admin page."},
    "Manage_Staffs" : {url: "staff-management/manage-staff",  description: "viewed Manage staff page."},
    "Manage_role" : {url: "staff-management/manage-roles",  description: "viewed Manage roles page."},
    "Manage_profile" : {url: "admin/profile-setting",  description: "viewed Manage profile page."},
    "Manage_Alberta" : {url:"scholarship-management/alberta-scholarship-master", description: "viewed Manage profile page."},
    "Mange_logout" : {url: "admin/dashboard", description: "viewed all page."},
    "sign_in": {url: "admin/login", description: "viewed login page."},
    "Manage_forgotpassword": {url: "admin/forgotpassword", description: "viewed forgotpassword page."},
    "User_verify_otp": {url: "admin/verifyotp", description: "viewed verify Otp page."},
    "Set_user_new_password": {url: "admin/resetpassword", description: "viewed new passwrod set page."},


    "Events_and_webinars": { url: "media-management/events-and-webinars", pagename: "manage events and webinars", description: "Viewed events and webinars page" },

    "module": {url: "course-management/manage-modules", pagename: "manage modules", description: "Viewed module page"},

    "external_module": {url: "course-management/manage-modules", pagename: "manage modules", description: "module added from manage course"},


    "topic": {url: "course-management/manage-topics", pagename: "manage topics", description: "Viewed topic page"},

    "video": {url: "course-management/manage-videos", pagename: "manage videos", description: "Viewed video page"},

    "question": {url: "course-management/manage-questions", pagename: "manage questions", description: "Viewed question page"},

    "tile": {url: "course-management/manage-tiles", pagename: "manage tiles", description: "Viewed tile page"},

    "download": {url: "course-management/manage-downloads", pagename: "manage downloads", description: "Viewed download page"},

    "expert_interview": {url: "course-management/manage-experts", pagename: "manage expert interview", description: "Viewed expert interview page"},

    "footer_banner": {url: "course-management/manage-footer-banners", pagename: "manage footer banner", description: "Viewed footer banner page"},

    "books": {url: "course-management/manage-books", pagename: "manage books", description: "Viewed book page"},

    "age_group": {url: "course-management/manage-age-groups", pagename: "manage age groups", description: "Viewed book page"},

    "courses": {url: "course-management/manage-courses", pagename: "manage courses", description: "Viewed courses page"},

    "coursesedit": {url: "course-management/course-edit", pagename: "manage courses", description: "Viewed courses page"},

    "coursesadd": {url: "course-management/course-add", pagename: "manage courses", description: "Viewed courses page"},



    "testimonials": {url: "course-management/manage-testimonials", pagename: "manage testimonials", description: "Viewed testimonials page"},

    "email_log": {url: "admin/email-logs", pagename: "manage email log", description: "Viewed email log page"},

    "industry": {url: "user-management/manage-industry", pagename: "manage industry", description: "Viewed Industry page"}


}