import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError, } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable()
export class HttpFrontEndService {
  email: any
  pass: any
  ip: any = '';

  private baseUrl = environment.FRONTEND_BASE_URI;
  AUTH_TOKEN = environment.auth_token;
  language = localStorage.getItem('language') ?? 'en';
  headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
  constructor(private httpClient: HttpClient, private toastr: ToastrService, private deviceService: DeviceDetectorService, private authService: AuthenticationService,) {
  }

  get(url: string, params?: any): Observable<any> {
    const headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
    const config = { headers: headers };
    return this.httpClient.get(this.baseUrl + url, config).pipe(catchError(this.errorHandler.bind(this)));
  }

  post(url: string, body: any, param?: any): Observable<any> {
    let headers
    if (param) headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language, param: JSON.stringify(param) };
    else headers = { 'x-access-token': this.AUTH_TOKEN, language: this.language };
    const config = { headers: headers };
    return this.httpClient.post(this.baseUrl + url, body, config).pipe(catchError(this.errorHandler.bind(this)));
  }

  put(url: string, body: any, params?: any): Observable<any> {
    return this.httpClient.put(this.baseUrl + url, body).pipe(catchError(this.errorHandler.bind(this)));
  }

  delete(url: string, params?: any): Observable<any> {
    return this.httpClient.delete(url).pipe(catchError(this.errorHandler.bind(this)));
  }

  getIPAddress() {
    return this.get(environment.IP);
  }

  private errorHandler(response: any) {
    this.toastr.error('', environment.serverErrorMessage);
    const error = response.error;
    let message;
    if (error) {

      const keys = Object.keys(error);
      const key = keys[0];
      message = error[key];
      if (response.status === 401) {
        // auth token delete
        // redirect login page
      }
      if (error[key] instanceof Array) {
        message = error[key][0];
      }
      if (key === 'isTrusted') {
        // this will occur when not connected to internet
      } else {
        message = key + ' : ' + message;
      }
    } else {
      message = response;
    }
    // call snackbar and show error with message
    return throwError({ messages: message, error });
  }

  async getLocalIPAddress() {
    if (this.ip == '') {
      let ip: any = await this.get(environment.IP).toPromise();
      this.ip = ip['ip'];
      return this.ip;
    } else
      return this.ip;
  }

}
