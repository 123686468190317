import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { CoreThemeCustomizerComponent } from '@core/components/theme-customizer/theme-customizer.component';



@NgModule({
  declarations: [CoreThemeCustomizerComponent],
  imports: [
    CommonModule,
    
  ],
 
  exports: [CoreThemeCustomizerComponent]
})
export class CoreThemeCustomizerModule {}
