export const NgxUiLoaderConfigObj = {
    bgsColor: '#0295c9',
    bgsOpacity: 0.5,
     bgsSize: 60,
    blur: 9,

    
    fastFadeOut: true,
    fgsColor: '#0295c9',
    overlayBorderRadius: "0",
    overlayColor: "rgba(40, 40, 40, 0.8)",
    pbColor: 'green'
  };
  