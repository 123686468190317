import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from '@fake-db/fake-db.service';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { coreConfig } from 'app/app-config';
import { AuthGuard, AnonGuard, AnonLoginGuard } from 'app/auth/helpers/auth.guards';
import { fakeBackendProvider } from 'app/auth/helpers'; // used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from 'app/auth/helpers';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';


import { AuthenticationComponent } from './pages/authentication/authentication.component';
import { HttpService } from './services/http.service';
import { HttpFrontEndService } from './services/http.frontend.service';
import { CommonValidationService } from './services/common.validation.service';
import { CommonObservableService } from './services/common-observable.service';
import { SafeHtmlModule } from "app/pipes/safe-html/safe-html.module";
import { ngxToastrConfig } from 'app/module/ngx-toaster';
import { HighchartsChartModule,  } from 'highcharts-angular';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { NgxUiLoaderConfigObj } from './module/ngx-ui-loader';
import { DashboardHttpService } from './services/dashboardHttp.service';
import { GlobalErrorHandler } from './services/chunk-error.service';
import { LocalStorageService } from './services/localStorage.service';
import { NewRoiComponent } from './pages/new-roi/new-roi.component';

const appRoutes: Routes = [
  
  {
    path: 'admin',
    loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [AnonGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'course-management',
    loadChildren: () => import('./pages/course-management/course-management.module').then(m => m.CourseManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/profile-setting/profile-setting.module').then(m => m.ProfileSettingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/email-template/email-template.module').then(m => m.EmailTemplateModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/email-logs/email-logs.module').then(m => m.EmailLogsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'staff-management',
    loadChildren: () => import('./pages/staff-management/staff-management.module').then(m => m.StaffManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'scholarship-management',
    loadChildren: () => import('./pages/scholarship-management/scholarship-management.module').then(m => m.ScholarshipManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-management',
    loadChildren: () => import('./pages/user-management/user-management.module').then(m => m.UserManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-profile/:id',
    loadChildren: () => import('./pages/user-profile-management/user-profile-management.module').then(m => m.UserProfileManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'post-management',
    loadChildren: () => import('./pages/post-management/post-management.module').then(m => m.PostManagementModule),
    canActivate: [AuthGuard]
  },
  {

    path: 'media-management',
    loadChildren: () => import('./pages/media-management/media-management.module').then(m => m.MediaManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'testimonial-management',
    loadChildren: () => import('./pages/testimonial-management/testimonial-management.module').then(m => m.TestimonialManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/global-setting/global-setting.module').then(m => m.GlobalSettingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/roi-report/roi-report.module').then(m => m.RoiReportModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/new-roi/new-roi.module').then(m => m.NewRoiModule),
    canActivate: [AuthGuard]
  },
  {
    path:'admin',
    loadChildren:() => import('./pages/manage-roi/manage-roi.module').then(m => m.ManageRoiModule),
    canActivate:[AuthGuard]
  },
  {
    path: '',
    redirectTo: '/admin/dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [
    AppComponent,
    
    AuthenticationComponent,
    

  ],
  imports: [
    BrowserModule,
    SafeHtmlModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),
    NgxUiLoaderModule.forRoot(NgxUiLoaderConfigObj),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      // relativeLinkResolution: 'legacy's
    }),
    NgbModule,
    ToastrModule.forRoot(ngxToastrConfig),
    TranslateModule.forRoot(),
    // ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    ContentHeaderModule,

    HighchartsChartModule,
    FormsModule,ReactiveFormsModule,
    NgxUiLoaderModule

  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthGuard,
    AnonGuard,
    AnonLoginGuard,
    CommonValidationService,
    HttpService,DashboardHttpService,
    HttpFrontEndService,
    CommonObservableService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // ! IMPORTANT: Provider used to create fake backend, comment while using real API
    fakeBackendProvider,
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    LocalStorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
