import { Component, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import * as _ from 'lodash';
import { Observable, Subject, Subscription, of, from, fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, retry, switchMap, takeUntil, catchError, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/auth/service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { age_group, chosenDate, commonconfig, commontypesforvalidation, datepicker } from 'app/commonconfig/config';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';
import { environment } from 'environments/environment';
import { HttpService } from 'app/services/http.service';
import { User } from 'app/auth/models';
import { coreConfig } from 'app/app-config';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { activity_type } from 'app/module/activity-type';
import { page_url } from 'app/module/pageUrl';
import { target_section } from 'app/module/targetSection';
import moment from 'moment';
import { CommonObservableService } from 'app/services/common-observable.service';
import { FormBuilder, FormControl, FormGroup, NgModel } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { validations } from 'app/module/validation';
import { HighlightSpanKind } from 'typescript';
import { id } from '@swimlane/ngx-datatable';
import { DashboardHttpService } from 'app/services/dashboardHttp.service';
import { ChangeDetectorRef } from '@angular/core';
import { NgZone } from '@angular/core';
import { EnrichedNumberFormatter } from 'app/pipes/number-formatter/number-formatter';

import 'daterangepicker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput1') searchInput1: ElementRef;
  @ViewChild('searchInput2') searchInput2: ElementRef;
  @ViewChild('searchInput3') searchInput3: ElementRef;
  @ViewChild('searchInput4') searchInput4: ElementRef;
  @ViewChild('searchInput10') searchInput10: ElementRef;


  selectAllChecked: any;
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;
  searchFilterForm: FormGroup;
  DateFilterForm : FormGroup;
  searchClientForm: FormGroup;
  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser: User;
  assetUrl: any;
  bas64_img;
  chosenDate: any;
  datefilter: any;
  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;
  activity_type = activity_type;
  page_url: any = page_url;
  target_section = target_section;

  menuOpen = false;
  menuBtnClick = false;
  filterOpen: boolean = false;
  filterBtnClick: boolean = false;
  commonconfig = commonconfig;
  showFilter: boolean = false;
  validations = validations;
  navBox: any;
  @ViewChild('daterangepicker') range: any;
  @ViewChild('select') select: ElementRef;
  selected: any;
  users: any[] = [];
  rolesListData: any[] = []
  company_name: any; selctedUserId: any; searchedDataName: any;
  _client_report_data: any = {};
  _client_report_id: any; _show_client_report: boolean = false;  // client report variables
  getAllDataSubscription: any;
  selectedUserClassCode:any;

  daterange: any
  date: any
  defaultLogo = `${environment.assetsUrl}images/ea-logos/logo-icon.png`;
  onouterclick = false
  onlistclick = false
  filterUsed = false
  clickout = false
  pointerevent

  reportSubs = new Subject();

  dropdownItems:any = [
    { label: 'Registration Date', value: 1 },
    { label: 'Last Login', value: 2 },
    { label: 'Registration & Last Login', value: 3 },
    { label: 'Registration OR Last Login', value: 4 }
  ];
  industry_types: any = [
    { id: 8, name: 'Client',client_name:"Categories Status"  },
    { id: 9, name: 'Prospect',client_name:"Categories Status"  },
    { id: 10, name: 'Pilot',client_name:"Categories Status"  },
    { id: 11, name: 'Former Client',client_name:"Categories Status"  },
    { id: 12, name: 'Partner',client_name:"Categories Status"  },
    { id: 1, name: 'A&C',client_name:"Categories Type" },
    { id: 4, name: 'Career College',client_name:"Categories Type" },
    { id: 2, name: 'D2C', disabled: false,client_name:"Categories Type" },
    { id: 3, name: 'Education',client_name:"Categories Type" },
    { id: 7, name: 'Elementary',client_name:"Categories Type" },
    { id: 6, name: 'High School',client_name:"Categories Type" },
    { id: 5, name: 'Post Secondary',client_name:"Categories Type" },
  ];
  indus_type: {};
  selectedItemIndex: any;
  public selectedDate({ start, end }: any, type?: any) {
     
    this.chosenDate = `${moment(start).format("ll")} - ${moment(end).format("ll")}`;
    
    let searchFilterForm = [];
    searchFilterForm.push({ created: `${moment(start).format("YYYY-MM-DD")}_${moment(end).format("YYYY-MM-DD")}` });
    this.daterange = this._cObsService.updateDateValue(searchFilterForm);
    this.date = searchFilterForm
    if (this.selctedUserId) {
      this._cObsService.sendSearchValueData(this.selctedUserId);
    }
    if (this.selctedUserId && type != 'resetAllFilter') {
      this._cObsService.sendSearchValueDataForClientHealthReport(this.selctedUserId);
    }
    if(type == 'resetAllFilter'){
      this.cos.updateAdvanceFilterKeyValue({});
    }
  }

  @HostBinding('class.fixed-top')
  public isFixed = false;
  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;
  data: any;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top' &&
      this.coreConfig.layout.type == 'horizontal'
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }
  @HostListener('window:scroll', ['$event'])

  // Private
  private _unsubscribeAll: Subject<any>;
  private searchSubject = new Subject<string>();

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */
  showNavbar: boolean = true;
  childclicked = false
  today = new Date();
  constructor(
    private modalService: NgbModal,
    private httpservice: HttpService,
    private cos: CommonObservableService,
    private _router: Router,
    private toastr: ToastrService,
    private _authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    public _cObsService: CommonObservableService,
    private fb: FormBuilder, 
    
    private renderer: Renderer2,
    private dashboardHttpService: DashboardHttpService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,

  ) {
    this.searchClientForm = this.formBuilder.group({
      search_client: ''
    });
    this.renderer.listen('window', 'click', (e: Event) => {

      if (!this.searchFilterForm.value.company_name) { this.users = []; this.stayUsers = []; this.display_value = []; this.searchFilterForm.reset(); this.placeholderText = '' }
      if (this.childclicked == true) {
      }
      this.childclicked = false

      this.menuBtnClick = false;
      this.onouterclick = true
      this.onlistclick = true

    });

    if (this._authenticationService.currentUser) this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));

    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'us'
      },
      fr: {
        title: 'French',
        flag: 'fr'
      },
      de: {
        title: 'German',
        flag: 'de'
      },
      pt: {
        title: 'Portuguese',
        flag: 'pt'
      }
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
        if (event.url != '/admin/dashboard') {
          this.resetAllFilter();
        }
      });



  }
  previousUrl: string = '';
  currentUrl: string = '';
  private routerSubscription: Subscription;

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig({ app: { appLanguage: language } }, { emitEvent: true });
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin');

    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem('prevSkin', this.currentSkin);
      this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
    }
  }

  /**
   * Logout method
   */
  logout() {
    this.saveLogs(activity_type.logout_button_clicked, this.page_url.Mange_logout, target_section.logout_users, this.currentUser);
    this._authenticationService.logout();
    this._router.navigate(['/admin/login']);
  }

  modalvaluedashboard
  return_dashboard
  isOpenFromDashboard
  placeholderText = ""
  private searchSub$ = new Subject<string>();
  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  RefreshClientDropdown = false
  RefreshCompanyDropdown = false
  RefreshTeacherDropdown = false
  RefreshIndustryDropdown = false


  ngOnInit(): void {
    this.RefreshClientDropdown = true
    this.RefreshCompanyDropdown = true
    this.RefreshTeacherDropdown = true
    this.RefreshIndustryDropdown=true
    this._cObsService.modalvaluedashboard.subscribe((res: any) => {
      this.modalvaluedashboard = res
      if (this.modalvaluedashboard == "false") {

        this.showNavbar = false;
        this.showFilter = false;
     }
    })

    this._cObsService.getGlobalSearchVariable.subscribe((res: any) => {
      if (res == 'RETURN') {
        this.onChange('RETURN');
        this.showNavbar = true;
        this.showFilter = false;
        this.navBox = undefined

      }
      else {
        this.showNavbar = false;
        this.selectedDate({ start: moment().subtract(1, 'year'), end: moment() })
      }
      // reset advance Filter
      this.resetAdvanceFilter();
    })
    this._cObsService.close_modal.subscribe((res: any) => {
      this.showNavbar = false;
    })

    this._cObsService.client_report_data.subscribe((res: any) => {
      if (res) {
        this._show_client_report = res._show_client_report;
        this.searchSubject.pipe(debounceTime(300)).subscribe((searchTerm: string) => {
          this.searchall(searchTerm);  // Call the search function after the debounce
        });
      }
    });


    let x = { id: 1, 'display_name': 'Acer' };
    this._cObsService.data.subscribe(response => {
      let x = response;
    });
    this.rolesListData = [
      {
        roleid: 1,
        display_name: 'Company'
      },
      {
        roleid: 2,
        display_name: 'Client'
      },
      {
        roleid: 3,
        display_name: 'User'
      },
    ]
    // get the currentUser details from localStorage
    this.currentUser = this._authenticationService.getLogedInData();
    if (this.currentUser) {
      this.getDataOfAdminProfile()
    }


    this.createSearchFrom();
    // Subscribe to the config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      this.horizontalMenu = config.layout.type === 'horizontal';
      this.hiddenMenu = config.layout.menu.hidden === true;
      this.currentSkin = config.layout.skin;

      // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
      if (this.coreConfig.layout.type === 'vertical') {
        setTimeout(() => {
          if (this.coreConfig.layout.navbar.type === 'fixed-top') {
            this.isFixed = true;
          }
        }, 0);
      }
      this.assetUrl = environment.assetsUrl;
    });
    this.selectedDate({ start: moment().subtract(1, 'year'), end: moment() })
    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == 'horizontal') {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
        const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
        if (isFixedTop) {
          this.isFixed = false;
        } else {
          this.isFixed = true;
        }
      });
    }
    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang
    });

    this.filterForm();
    this.onFilterCompany()
    this.onFilterClient()
    this.onFilterTeacher()
    this.onFilterIndustry()
    this._cObsService.filtersValue({ filtervalue: '', type: '' })
    this.cos.updateAdvanceFilterKeyValue({})
  }

  /* For Reset All Filters Including child */
  resetAllFilter() {
    /* new */

    let start = moment().subtract(1, 'year')
    let end = moment()

    let date = `${moment(start).format("YYYY-MM-DD")}_${moment(end).format("YYYY-MM-DD")}`
    let d = this.getfiltervalue()
    this._cObsService.filtersValue({ filtervalue: d, type: '' })
    let a = document.getElementById('fileter-b')?.classList.remove('active')
    if (!this.industry_type.value && !this.industry_filter.value && !this.company_filter.value && !this.clients_filter.value && !this.teacher_filter.value && this.date[0].created == date && !this.searchFilterForm.value.company_name) {
      return
    }

    /* new */
    // this.cos.updateAdvanceFilterKeyValue({});
    this.selectedDate({ start, end }, 'resetAllFilter')
    this.onSelectRole('Select', 0)
    this.changeFilter([], 'Industry')
    this.teacher_filter.reset();
    this.industry_type.reset()
    this._cObsService.sendSearchValueData('resetAllFilter');
    this._cObsService.sendSearchValueDataForClientHealthReport('resetAllFilter');
    this.IsDisplayFilterd = false
    this.placeholderText = ''
  }

  ftValue: any;
  getDataOfAdminProfile() {

    let data = this._authenticationService.getLogedInData();
    if (data) {
      // Check if data exists in local storage
      let storedData = localStorage.getItem('adminProfileData');
      if (storedData) {
        this.data = JSON.parse(storedData);
        this.bas64_img = `${this.assetUrl}admin/image/${this.data.title}`;
      } else {
        // If no data in local storage, fetch from server
        let data1 = { role_id: data.role_id, id: data.id, image_id: data.image_id };
        this.httpservice.post('getProfileSettingData', data1).subscribe((res) => {
          if (res.status) {
            this.data = res.data[0];
            this.bas64_img = `${this.assetUrl}admin/image/${this.data.title}`;
            // Save data to local storage
            localStorage.setItem('adminProfileData', JSON.stringify(this.data));
          }
        });
      }
    }
  }
  //---------------------star savelogs---------------------//
  saveLogs(type: string, pageURl: string, targetSection: string, data: any) {
    this.httpservice.saveActivityLog(type, pageURl, targetSection, data)
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions


    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.getAllDataSubscription?.unsubscribe();
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  toggleSearchbar() {
    document.getElementById('searchbar').classList.toggle('open-srearchbar');
  }

  openChildAccordion: number;
  lastOpenedChildIndex: number;

  toggleAccordian(index) {
    this.openChildAccordion = index;
    if (this.lastOpenedChildIndex != undefined && this.lastOpenedChildIndex == index) {
      this.openChildAccordion = undefined;
      this.lastOpenedChildIndex = undefined;
      return;
    }
    this.lastOpenedChildIndex = index;
  }

  async export() {
  }

  showHideColumn() { this.menuOpen = !this.menuOpen; }
  preventCloseGrade() { this.menuBtnClick = true; }

  openAdvancedFilter() {

    if (this.advancedData) {
      this.showFilter = !this.showFilter;

    }
    if (this.showFilter == true) {
      this.getDropdownIndustry();
    }
    setTimeout(() => {
      this.navBox = document.getElementsByClassName('advanc-nav-filter')[0]?.clientHeight;
      (document.getElementsByTagName('app-dashboard')[0].getElementsByClassName('content-wrapper')[0] as HTMLElement).style.paddingTop = this.navBox + 'px';

      if (this.navBox == undefined) {
        (document.getElementsByTagName('app-dashboard')[0].getElementsByClassName('content-wrapper')[0] as HTMLElement).style.paddingTop = "0px"
      }
    }, 100);

  }

  isVal: number;
  sendId() {
    this.isVal = 1;
    this._client_report_id = 1;
    this._show_client_report = true;
    this._client_report_data =
      { _client_report_id: this._client_report_id, _show_client_report: this._show_client_report }
    this._cObsService.sendReportId(this._client_report_data);
    this.searchall(this.searchFilterForm.value.company_name);
    this._cObsService.setData('HealthReport');
  }
  companyData: any;
  listData: any = [];
  dat: any
  stayUsers: any;
  display_value

  isLoadingFilter: number = 0;


  user: any[] = [];
  visibleUsers: any[] = [];
  buffer = 10;
  currentIndex = 0;
  onScroll(event: any) {
    if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2) {
      const newUsers = this.addItems(this.currentIndex, this.buffer, this.user);
      this.visibleUsers = [...this.visibleUsers, ...newUsers];
      this.currentIndex += this.buffer;
    }
  }
  
  async searchall(value: string) {
    this.isLoadingFilter = 1;

    if (value == '') {
      return;
    }

    if (value.length && value) {
      document.getElementById('searchinput')?.classList.add('toogle-nav-width');
      this.users = null;
      this.childclicked = true;
      let filterObj: any = {};
      filterObj['display_name'] = value?.replace(/^\s+|\s+$/g, '');
      filterObj['role_id'] = this.selectedRoleId ? this.selectedRoleId : 0;

      if (this._show_client_report == true) { filterObj['Not_role_id'] = 2; }
      if (filterObj['role_id'] == 0) {
        delete filterObj['role_id'];
      }

      this.getAllDataSubscription?.unsubscribe();
      this.getAllDataSubscription = this.dashboardHttpService.post('/dashboard/getallData', filterObj).subscribe((res) => {
        if (res.status) {
          let x = res.data;
          this.users = res.data;
          this.stayUsers = this.users;
          this.display_value = value.trim();
          this.searchFilterForm.value.company_name = value.trim();

          let teacher = res.teacher;
          let user = res.user;
          let client = res.client;
          let company = res.company;
          let companyAdmin = res.companyAdmin;
          let clientAdmin = res.clientAdmin;

          this.dat = [
            {
              parentName: 'User',
              Totaluser: res.user?.length,
              childProperties: user,
              role_id: 1,
            },
            {
              parentName: 'Client',
              Totaluser: client?.length,
              childProperties: client,
              role_id: 2,
            },
            {
              parentName: 'Client Admin',
              Totaluser: clientAdmin?.length,
              childProperties: clientAdmin,
              role_id: 3,
            },
            {
              parentName: 'Company',
              Totaluser: company?.length,
              childProperties: company,
              role_id: 4,
            },
            {
              parentName: 'Company Admin',
              Totaluser: companyAdmin?.length,
              childProperties: companyAdmin,
              role_id: 5,
            },
            {
              parentName: 'Teacher',
              Totaluser: teacher?.length,
              childProperties: teacher,
              role_id: 8,
            },
          ];

          if (user?.length == 0) {
            this.dat = this.dat.filter((e) => e.parentName != 'User');
          }
          if (client?.length == 0) {
            this.dat = this.dat.filter((e) => e.parentName != 'Client');
          }
          if (company?.length == 0) {
            this.dat = this.dat.filter((e) => e.parentName != 'Company');
          }
          if (teacher?.length == 0) {
            this.dat = this.dat.filter((e) => e.parentName != 'Teacher');
          }
          if (companyAdmin?.length == 0) {
            this.dat = this.dat.filter((e) => e.parentName != 'Company Admin');
          }
          if (clientAdmin?.length == 0) {
            this.dat = this.dat.filter((e) => e.parentName != 'Client Admin');
          }

          this.isLoadingFilter = 2;

          if (this.dat?.length == 1) {
            this.lastOpenedChildIndex = undefined;
            this.toggleAccordian(0);
          }
        }
      });
    } else {
      this.users = [];
    }
  }

  IsDisplayFilterd: boolean = false;
  searchFilterValue: any;
  private searchTimeout: any;

  searchFilter(filterValue?: string) {
    if (!filterValue || filterValue == '' || filterValue == undefined) {
      this.onChange('RETURN_BACK');
      this.placeholderText = '';
      document.getElementById('searchinput')?.classList.remove('toogle-nav-width');
    }

    this.lastOpenedChildIndex = undefined;
    this.searchFilterForm.patchValue({ company_name: filterValue });
    this.openChildAccordion = undefined;
    this.isLoadingFilter = 1;
    this.searchFilterValue = filterValue;

    if (filterValue?.length <= 2) {
      this.dat = [];
      this.users = [];
      this.IsDisplayFilterd = false;
      return false;
    } else {
      this.IsDisplayFilterd = true;
      this.onouterclick = false;
      this.users = [];
      if (!filterValue) { this.stayUsers = []; }

      this.ftValue = filterValue;

      // Trigger the debounced value change
      if (this.ftValue?.length >= 3) {
        this.searchSubject.next(this.ftValue); // Trigger debounce
      } else {
        this.users = [];
      }
    }
  }

  createSearchFrom() {
    this.searchFilterForm = this.fb.group({
      company_name: [],
    });
    this.searchFilterForm.valueChanges
      .pipe(debounceTime(validations.debounceSearchTime)).subscribe(value => {
        this.isLoadingFilter = 1;
        if (value.company_name == '') {
          return
        }
        if (value.company_name.length > 3 && value.company_name) {
          document.getElementById('searchinput').classList.add('toogle-nav-width')
          this.users = null;
          this.childclicked = true
          let filterObj = {};
          filterObj['display_name'] = value.company_name?.replace(/^\s+|\s+$/g, '');
          filterObj['role_id'] = this.selectedRoleId ? this.selectedRoleId : 0;
          if (this._show_client_report == true) { filterObj['Not_role_id'] = 2 }
          if (filterObj['role_id'] == 0) {
            delete filterObj['role_id']
          }
          this.getAllDataSubscription?.unsubscribe();
          this.getAllDataSubscription = this.dashboardHttpService.post('/dashboard/getallData', filterObj).subscribe((res) => {
            if (res.status) {

              let x = []
              x = res.data;
              this.users = [];
              this.users = res.data;
              this.stayUsers = this.users
              this.display_value = value.company_name.trim();
              this.searchFilterForm.value.company_name = value.company_name.trim();

              let user: any, client: any, company: any, teacher: any, clientAdmin: any, companyAdmin: any, staff: any
              teacher = res.teacher
              user = res.user
              client = res.client
              company = res.company
              companyAdmin = res.companyAdmin
              clientAdmin = res.clientAdmin

              this.dat = [
                {
                  "parentName": "User",
                  "Totaluser": user?.length,
                  "childProperties": user,
                  "role_id": 1
                },
                {
                  "parentName": "Client",
                  "Totaluser": client?.length,
                  "childProperties": client,
                  "role_id": 2
                }, {
                  "parentName": "Client Admin",
                  "Totaluser": clientAdmin?.length,
                  "childProperties": clientAdmin,
                  "role_id": 3
                }, {
                  "parentName": "Company",
                  "Totaluser": company?.length,
                  "childProperties": company,
                  "role_id": 4
                }, {
                  "parentName": "Company Admin",
                  "Totaluser": companyAdmin?.length,
                  "childProperties": companyAdmin,
                  "role_id": 5
                }, {
                  "parentName": "Teacher",
                  "Totaluser": teacher?.length,
                  "childProperties": teacher,
                  "role_id": 8
                },
              ];

              let y = [];

              if (user?.length == 0) {
                this.dat = this.dat.filter(e => e.parentName != 'User');
              }
              if (client?.length == 0) {
                this.dat = this.dat.filter(e => e.parentName != 'Client');
              }
              if (company?.length == 0) {
                this.dat = this.dat.filter(e => e.parentName != 'Company');
              }
              if (teacher?.length == 0) {
                this.dat = this.dat.filter(e => e.parentName != 'Teacher');
              }
              if (companyAdmin?.length == 0) {
                this.dat = this.dat.filter(e => e.parentName != 'Company Admin');
              }
              if (clientAdmin?.length == 0) {
                this.dat = this.dat.filter(e => e.parentName != 'Client Admin');
              }
              this.isLoadingFilter = 2;

              if (this.dat?.length == 1) {
                this.lastOpenedChildIndex = undefined;
              
                this.toggleAccordian(0);
              }
            } else {
            }
          });
        }
        else {
          this.users = []
        }
      });
  }
  // visibleUsers
  addItems(currentIndex: number, bufferSize: number, allUsers: any[]): any[] {
    // Calculate the next slice of users based on the provided currentIndex and bufferSize
    const nextUsers = allUsers.slice(currentIndex, currentIndex + bufferSize);
    return nextUsers;
  }
  onChangeRole(val: any) {
  }

  onClick() {
  }



  // NG SELECT CODE FOR SEARCH

  @ViewChild('modalInfo') private modalInfo: ElementRef;

  searchVal: any;
  onChange(val?) {
    if (!this.selctedUserId || this.selctedUserId == 0) {
      if (val == 'RETURN_BACK') {
        this.getDropdownIndustry();
        return;
      }
      else {
      }
    }
    if (val == 'RETURN') {
      this.users = [];
      this.searchFilterForm.reset();
      this.advancedData = {};
      this.industryData = [];
      this.companyData = [];
      if (this.industry_filter) this.industry_filter.reset();
      if (this.company_filter) this.company_filter.reset();
      if (this.clients_filter) this.clients_filter.reset();
      if (this.courses_filter) this.courses_filter.reset();
      this.getDropdownIndustry();
      return;
    }
    if (val == undefined) {
      this.filtervalue = ''; this.users = [];
      if (this.advancedData) {
        this.advancedData = {};
        this.industryData = [];
        this.companyData = [];
        if (this.industry_filter) this.industry_filter.reset();
        if (this.company_filter) this.company_filter.reset();
        if (this.clients_filter) this.clients_filter.reset();
        if (this.courses_filter) this.courses_filter.reset();
        this.getDropdownIndustry();
      }

    }
    
    if (val && Object.keys(val).length > 0) {
      if (val.display_name == 'Industry' || val.display_name == 'Company' ||
        val.display_name == 'Client' || val.display_name == 'Course') {
        this.users = [];
        this.searchFilterForm.reset();
      }
      else {

        this.advancedData = {};
        this.industryData = [];
        this.companyData = [];
        this.industry_filter.reset();
        this.company_filter.reset();
        this.clients_filter.reset();
        this.courses_filter.reset();
        if (val.role_id == 4 || val.role_id == 2) {
          this.getDropdownIndustry(val);
        }
        else { this.getDropdownIndustry(); }
      }
    } else {
      this.selctedUserId = '0'
      this._cObsService.sendSearchValueData(this.selctedUserId);
      this._cObsService.sendSearchValueDataForClientHealthReport(this.selctedUserId);
    }

    this.searchedDataName = val ? val.display_name : undefined;
    if ((this.searchedDataName == null || this.searchedDataName.length == 0 || this.searchedDataName == undefined || this.searchedDataName == '')) {
      this.selctedUserId = '0'
      this._cObsService.sendSearchValueData(this.selctedUserId);
      this._cObsService.sendSearchValueDataForClientHealthReport(this.selctedUserId);
      this.searchFilterForm.reset();
    }
    else {
      this.selctedUserId = val;
      if (val.role_id === 1) {
        this.selctedUserId.values = true        
        this.modalOpenInfo(this.modalInfo,this.selctedUserId.user_id,this.selctedUserId.client_class_code)
        setTimeout(() => { this.searchFilterForm.reset(); }, 1000);
      }
      else if (val.role_id === 7) {

        this._cObsService.sendSearchValueData(this.selctedUserId);
        this._cObsService.sendSearchValueDataForClientHealthReport(this.selctedUserId);
        this._router.navigate(['/admin/dashboard'])
      }
      else if (val.role_id === 2) {

        this._cObsService.sendSearchValueData(this.selctedUserId);
        this._cObsService.sendSearchValueDataForClientHealthReport(this.selctedUserId);
      }
      else if (val.role_id === 9) {

        this._cObsService.sendSearchValueData(this.selctedUserId);
        this._cObsService.sendSearchValueDataForClientHealthReport(this.selctedUserId);
      }
      else if (val.role_id === 3) { // client admin

        this._cObsService.sendSearchValueData(this.selctedUserId);
        this._cObsService.sendSearchValueDataForClientHealthReport(this.selctedUserId);
      }
      else if (val.role_id === 5) { // comapny admin

        this._cObsService.sendSearchValueData(this.selctedUserId);
        this._cObsService.sendSearchValueDataForClientHealthReport(this.selctedUserId);
      }
      else if (val.role_id === 8) { // teacher

        this._cObsService.sendSearchValueData(this.selctedUserId);
        this._cObsService.sendSearchValueDataForClientHealthReport(this.selctedUserId);
      }
      else {
        this._cObsService.sendSearchValueData(this.selctedUserId);
        this._cObsService.sendSearchValueDataForClientHealthReport(this.selctedUserId);
        
      }


    }

    if (this.searchedDataName = "Industry") this.searchedDataName = '';
    
  }




  getUserIdsFirstWay(el: any) {
    this.selctedUserId = null
  }

  selectedRoleName = 'Select'; selectedRoleId: any;

  onSelectRole(val1: any, val2: any) {
    this.lastOpenedChildIndex = undefined;
    this.selectedRoleName = val1;
    this.selectedRoleId = val2 == 0 ? 0 : val2;
    let selectRoleValue = 'RETURN_BACK'
    this.onChange(selectRoleValue);
    this.searchFilter(this.searchFilterValue);
    if (!this.resetAdvanceFilterCalled){this.resetAdvanceFilter(); this.resetAdvanceFilterCalled = true; }   
    this.placeholderText = ''
  }
  selectedDateType = 'Advance Filter';
  dateForAdvanceFilter :any
  onSelectDate(val1: any, val2: any, i?:any) {
    this.selectedItemIndex = i;
    if (val1 == 'Advance Filter' && val2 == 0) {
      this.resetAdvanceFilter();
      this.resetAdvanceFilterCalled = false;
    } else {
      this.selectedDateType = val1;
      this.cos.updateAdvanceFilterKeyValue({ id: val2, type: this.selectedDateType })
      this.resetAdvanceFilterCalled = false;
      this.onChange('RETURN_BACK');
      this.selectedRoleName = 'Select';
      this.selectedRoleId = 0;
      this.industry_type.reset();
    }
  }

  resetAdvanceFilterCalled = false;
  resetAdvanceFilter(){
    this.selectedDateType = 'Advance Filter';
    this.selectedItemIndex = '';
    this.cos.updateAdvanceFilterKeyValue({})
  }

  // ADVANCED DROPDOWN CODE

  filterForm() {

    this.datefilter = new FormControl('');
    this.courses_filter = new FormControl('');
    this.industry_filter = new FormControl('');
    this.company_filter = new FormControl('');
    this.clients_filter = new FormControl('');
    this.industry_type = new FormControl('');
    this.teacher_filter = new FormControl('')
  }
  showMore: boolean = true;
  onShowMore() {

  }

  client_length: any
  clearIndustry() { }
  obj: any = {}; advancedData: any = {}
  unsub: any
  getDropdownIndustry(value?) {
    let currentUser = this._authenticationService.getLogedInData();
    if (!currentUser) return false;


    if (!this.advancedData) {
    }
    else {
      this.unsub?.unsubscribe()
      if (this.advancedData['Industry'] && this.advancedData['Company'] && this.advancedData['Client']) {
        this.obj = {}
        let d
        let a = ''
        this.obj['client_id'] = this.advancedData['Client'];
        this.obj['company_id'] = this.advancedData['Company'];
        this.obj['industry_id'] = this.advancedData['Industry'];
        this.obj['industry_type'] = this.advancedData['Industry_type'];
        if (this.teacher_filter.value?.length) {
          this.obj['is_teacher'] = 1
          d = this.getfiltervalue()
          this._cObsService.filtersValue({ filtervalue: d })
        }
        else {
          d = this.getfiltervalue()
          this._cObsService.filtersValue({ filtervalue: d })
        }
        delete this.obj[`selected`]
        this.unsub = this.httpservice.commonPost('/dashboardCompaniesService/getCompanyListWhenIndustry', this.obj).subscribe((res) => {
          if (res.status) {
            this.industryData = res.data.Industry;
            this.indData = res.data.Industry;
            this.companyDatas = res.data.Company;
            this.clientData = res.data.Client;
            if (!this.teacher_filter.value?.length) {
              this.teacherData = res.data.Teacher
            }
            this.courseData = res.data.Course;
            this.client_length = res.data.Client.length
            d = this.getfiltervalue()
            this._cObsService.filtersValue({ filtervalue: d })
            this._cObsService.sendAllTypeUsers({ industries: res.data.Industry, companies: res.data.Company, clients: res.data.Client });
            this.loadData()
            this.sortingOfIndustry()
            this.sortingOfCompany()
            this.sortingOfClient()

          }
          if (this.advancedData) {
          }
          else {
            this.showFilter = false
          }
        });
      }


      else if (this.advancedData['Industry'] && this.advancedData['Company']) {
        this.obj = {}
        this.obj['company_id'] = this.advancedData['Company']; delete this.obj[`selected`]
        this.obj['industry_id'] = this.advancedData['Industry'];
        this.obj['industry_type'] = this.advancedData['Industry_type']
        let d

        if (this.teacher_filter.value?.length) {

          this.obj['is_teacher'] = 1
          d = this.getfiltervalue()
          this._cObsService.filtersValue({ filtervalue: d })
        }
        this.unsub = this.httpservice.commonPost('/dashboardCompaniesService/getCompanyListWhenIndustry', this.obj).subscribe((res) => {
          if (res.status) {
            this.industryData = res.data.Industry;
            this.indData = res.data.Industry;
            this.companyDatas = res.data.Company;
            this.clientData = res.data.Client;
            if (!this.teacher_filter.value?.length) {
              this.teacherData = res.data.Teacher
            }
            this.courseData = res.data.Course;
            this.client_length = res.data.Client.length
            let d = this.getfiltervalue()
            this._cObsService.filtersValue({ filtervalue: d })
            this._cObsService.sendAllTypeUsers({ industries: res.data.Industry, companies: res.data.Company, clients: res.data.Client });
            this.loadData()
            this.sortingOfIndustry()
            this.sortingOfCompany()
            this.sortingOfClient()

          }
        });
      }
      else if (this.advancedData['Industry'] && this.advancedData['Client']) {
        this.obj = {}
        let d
        this.obj['industry_id'] = this.advancedData['Industry'];
        this.obj['client_id'] = this.advancedData['Client'];
        this.obj['industry_type'] = this.advancedData['Industry_type'];
        if (this.teacher_filter.value?.length) {

          this.obj['is_teacher'] = 1
          d = this.getfiltervalue()
          this._cObsService.filtersValue({ filtervalue: d })
        }
        delete this.obj[`selected`]

        this.unsub = this.httpservice.commonPost('/dashboardCompaniesService/getCompanyListWhenIndustry', this.obj).subscribe((res) => {
          if (res.status) {
            this.industryData = res.data.Industry;
            this.indData = res.data.Industry;
            this.companyDatas = res.data.Company;
            this.clientData = res.data.Client;
            if (!this.teacher_filter.value?.length) {
              this.teacherData = res.data.Teacher
            }
            this.courseData = res.data.Course;
            this.client_length = res.data.Client.length
            d = this.getfiltervalue()
            this._cObsService.filtersValue({ filtervalue: d })
            this._cObsService.sendAllTypeUsers({ industries: res.data.Industry, companies: res.data.Company, clients: res.data.Client });
            this.loadData()
            this.sortingOfIndustry()
            this.sortingOfCompany()
            this.sortingOfClient()

          }
        });
      }
      else if (this.advancedData['Company'] && this.advancedData['Client']) {


        this.obj = {}

        let d
        this.obj['company_id'] = this.advancedData['Company'];
        this.obj['client_id'] = this.advancedData['Client'];
        this.obj['industry_type'] = this.advancedData['Industry_type'];
        delete this.obj[`selected`]
        if (this.teacher_filter.value?.length) {
          this.obj['is_teacher'] = 1
          d = this.getfiltervalue()
          this._cObsService.filtersValue({ filtervalue: d })
        }
        if (!this.teacher_filter.value?.length) {

          this.unsub = this.httpservice.commonPost('/dashboardCompaniesService/getCompanyListWhenIndustry', this.obj).subscribe((res) => {
            if (res.status) {
              this.industryData = res.data.Industry;
              this.indData = res.data.Industry;
              this.companyDatas = res.data.Company;
              this.clientData = res.data.Client;
              if (!this.teacher_filter.value?.length) {
                this.teacherData = res.data.Teacher
              }
              this.courseData = res.data.Course;
              this.client_length = res.data.Client.length
              d = this.getfiltervalue()
              this._cObsService.filtersValue({ filtervalue: d })
              this._cObsService.sendAllTypeUsers({ industries: res.data.Industry, companies: res.data.Company, clients: res.data.Client });
              this.loadData()
              this.sortingOfCompany()
              this.sortingOfClient()
              this.sortingOfIndustry()

            }
          });
        }
      }
      else if (this.advancedData['Industry']) {
        this.obj = {}
        this.obj['industry_id'] = this.advancedData['Industry']; delete this.obj[`selected`]
        this.obj['industry_type'] = this.advancedData['Industry_type'];
        this.unsub = this.httpservice.commonPost('/dashboardCompaniesService/getCompanyListWhenIndustry', this.obj).subscribe((res) => {
          if (res.status) {
            this.industryData = res.data.Industry;
            this.indData = res.data.Industry;
            this.companyDatas = res.data.Company;
            if (!this.industry_type.value?.length) {

              this.clientData = res.data.Client;
              this.teacherData = res.data.Teacher
              this.courseData = res.data.Course;
            }
            this.client_length = res.data.Client.length
            let d = this.getfiltervalue()
            this._cObsService.filtersValue({ filtervalue: d })

            this.advancedData['Company'] = this.companyDatas.map(e => { return e.id }).join(',')
            if (this.advancedData['Company']) this.comp_val = { id: this.advancedData['Company'], role_id: 4, display_name: 'Company', type: this.advancedData['Industry_type'], notCompany: true }
            this.onChange(this.comp_val);

            this._cObsService.sendAllTypeUsers({ industries: res.data.Industry, companies: res.data.Company, clients: res.data.Client });
            this.loadData()
            this.sortingOfIndustry()
            this.sortingOfCompany()
            this.sortingOfClient()

          }
        });

      }
      else if (this.advancedData['Company']) {
        this.obj = {}
        this.obj['company_id'] = this.advancedData['Company']; delete this.obj[`selected`]
        this.obj['industry_type'] = this.advancedData['Industry_type']
        this.unsub = this.httpservice.commonPost('/dashboardCompaniesService/getCompanyListWhenIndustry', this.obj).subscribe((res) => {
          if (res.status) {
            this.RefreshClientDropdown = true
            this.RefreshCompanyDropdown = true
            this.industryData = res.data.Industry;
            this.indData = res.data.Industry;
            if (!this.industry_type.value?.length) {

              this.companyDatas = res.data.Company;
            }
            this.clientData = res.data.Client;
            this.courseData = res.data.Course;
            this.teacherData = res.data.Teacher
            this.client_length = res.data.Client?.length
            if (this.industry_type.value?.length && !this.teacher_filter.value?.length && !this.company_filter.value?.length && !this.industry_filter.value?.length && !this.clients_filter.value?.length) {
              this.advancedData['Company'] = this.companyDatas.map(e => { return e.id }).join(',')

              if (this.advancedData['Company']) this.comp_val = { id: this.advancedData['Company'], role_id: 4, display_name: 'Company', type: this.advancedData['Industry_type'] }
              this.onChange(this.comp_val);

            }
            else if (this.company_filter.value?.length && !this.industry_type.value?.length) {
              this.comp_val = { id: this.company_filter.value, role_id: 4, display_name: 'Company' }
            }
            let d = this.getfiltervalue()
            this._cObsService.filtersValue({ filtervalue: d })

            this._cObsService.sendAllTypeUsers({ industries: res.data.Industry, companies: res.data.Company, clients: res.data.Client });
            this.loadData()
            this.sortingOfCompany()
            this.sortingOfIndustry()
            this.sortingOfClient()
          }
        });
      }
      else if (this.advancedData['Client'] && !this.teacher_filter.value?.length) {
        this.obj = {}
        this.obj['client_id'] = this.advancedData['Client']; delete this.obj[`selected`]
        if (this.teacher_filter.value?.length) {

          this.obj['is_teacher'] = 1
        }
        this.unsub = this.httpservice.commonPost('/dashboardCompaniesService/getCompanyListWhenIndustry', this.obj).subscribe((res) => {
          if (res.status) {

            this.industryData = res.data.Industry;
            this.indData = res.data.Industry;
            this.companyDatas = res.data.Company;
            this.clientData = res.data.Client;
            this.teacherData = res.data.Teacher
            this.courseData = res.data.Course;
            this.client_length = res.data.Client.length
            let d = this.getfiltervalue()
            this._cObsService.filtersValue({ filtervalue: d })
            this._cObsService.sendAllTypeUsers({ industries: res.data.Industry, companies: res.data.Company, clients: res.data.Client });
            this.loadData()
            this.sortingOfClient()
            this.sortingOfIndustry()
            this.sortingOfCompany()


          }
        });
      }
      else if (this.advancedData['Client'] && this.teacher_filter.value?.length) {
        this.obj = {}
        this.obj['client_id'] = this.teacher_filter.value?.map(e => e).join(', '); delete this.obj[`selected`]
        if (this.teacher_filter.value?.length) {
          this.obj['is_teacher'] = 1
        }

        this.unsub = this.httpservice.commonPost('/dashboardCompaniesService/getCompanyListWhenIndustry', this.obj).subscribe((res) => {
          if (res.status) {
            let d = this.getfiltervalue()
            this._cObsService.filtersValue({ filtervalue: d })
            this._cObsService.sendAllTypeUsers({ industries: res.data.Industry, companies: res.data.Company, clients: res.data.Client });


          }
        });
      }
      else if (this.advancedData['Industry_type']) {
        // it's use for update Industry_type filter by Industry type and satuts
        this.RefreshIndustryDropdown = true
        // add for pilot status
        this.comp_val = {}
        // add for pilot status
        let client_status = value        
        this.obj = {}
        this.obj['industry_type'] = this.advancedData['Industry_type']; delete this.obj[`selected`]
        // for add category filter
        if (client_status?.length > 0) {
          this.obj['client_status'] = client_status          
        }
        // for add category filter

        delete this.advancedData['Company']
        this.unsub = this.httpservice.commonPost('/dashboardCompaniesService/getCompanyListWhenIndustry', this.obj).subscribe((res) => {
          if (res.status) {
            this.industryData = res.data.Industry;
            this.indData = res.data.Industry;
            this.companyDatas = res.data.Company;
            this.clientData = res.data.Client;
            this.courseData = res.data.Course;
            this.teacherData = res.data.Teacher
            this.client_length = res.data.Client.length
            this.advancedData['Company'] = this.companyDatas.map(e => { return e.id }).join(',')
            if (this.advancedData['Company']) this.comp_val = { id: this.advancedData['Company'], role_id: 4, display_name: 'Company', type: this.advancedData['Industry_type'] }
            
            // add new condition for pilot
            if( Object.keys(this.comp_val).length === 0){
              this.comp_val = {id: '0', role_id: 4, display_name: 'Company', type: '1'}
            }
            // add new condition for pilot
            
            this.onChange(this.comp_val);
            let d = this.getfiltervalue()
            this._cObsService.filtersValue({ filtervalue: d })
            this._cObsService.sendAllTypeUsers({ industry_type: res.data.Industy_type, industries: res.data.Industry, companies: res.data.Company, clients: res.data.Client });
            this.loadData()
            this.sortingOfIndustry() 
            this.sortingOfCompany()
            this.sortingOfClient()

          }
        });
      }
      else {
        this.RefreshIndustryDropdown = true
        this.comp_val = {}
        
        let client_status = value || '';
        this.obj = {};
        this.obj = { selected: 'All_selected' }
        if (client_status?.length > 0) {
          this.obj['client_status'] = client_status          
        }
        // for add category status filter
        this.unsub = this.httpservice.commonPost('/dashboardCompaniesService/getCompanyListWhenIndustry', this.obj).subscribe((res) => {
          if (res.status) {
            this.industryData = res.data.Industry;
            this.indData = res.data.Industry;
            this.companyDatas = res.data.Company;
            this.clientData = res.data.Client;
            this.teacherData = res.data.Teacher
            this.courseData = res.data.Course;
            let d = this.getfiltervalue()
            this._cObsService.filtersValue({ filtervalue: d })
            this._cObsService.sendAllTypeUsers({ industries: res.data.Industry, companies: res.data.Company, clients: res.data.Client });
           
            

          

            if (client_status?.length > 0) {
              this.advancedData['Company'] = this.companyDatas.map(e => { return e.id }).join(',')
              if (this.advancedData['Company']) this.comp_val = { id: this.advancedData['Company'], role_id: 4, display_name: 'Company', type: this.advancedData['Industry_type'] }
              if( Object.keys(this.comp_val).length === 0){
                this.comp_val = {id: '0', role_id: 4, display_name: 'Company', type: '1'}
              }
              this.onChange(this.comp_val);
            }
            
          
            this.loadData()
            let patchId;
            if (value) {
              if (value.role_id == 4) {
                this.companyDatas.filter((val: any) => {
                  if (val.id == value.id) {
                    let u = val.id

                    this.company_filter.patchValue([u])
                  }

                });

              }
              else {
                this.clientData.filter((val: any) => {
                  if (val.id == value.id) {
                    let v = val.id

                    this.clients_filter.patchValue([v])
                  }

                });

              }

            }

          }
        });



      }

    }

  }
  loadData() {
    if(this.RefreshIndustryDropdown==true){
      this.onFilterIndustry()
      this.RefreshIndustryDropdown =false
    }
    if (this.RefreshCompanyDropdown == true) {
      this.onFilterCompany();
      this.RefreshCompanyDropdown = false
    }
    if (this.RefreshClientDropdown == true) {
      this.onFilterClient();
      this.RefreshClientDropdown = false
    }
    this.RefreshTeacherDropdown = true
    if (this.RefreshTeacherDropdown == true) {
      this.onFilterTeacher()
      this.RefreshTeacherDropdown = false
    }
   

    this._cObsService.Coursedata(this.courseData)
  }
  Company: any;

  indData
  indus_val: any = {}; comp_val: any = {}; client_val: any = {}; course_val: any = {}; teacher_val: any = {}
  filtervalue: any = {}; courses_filter!: FormControl; industry_filter!: FormControl; company_filter!: FormControl; clients_filter!: FormControl; industry_type!: FormControl; teacher_filter!: FormControl
  videoData: any = []; courseData: any = []; industryData: any = []; companyDatas: any = []; clientData: any = []; teacherData: any = []
  industryDataBuffer=[]
  industryDataBuffer$:Observable<any>
  industryInput$=new Subject<string>()
  category = 0;
  industry: any = 0;
  selectCompany: any = 0;
  Client: any = 0;
  Teacher: any = 0;
  getfiltervalue() {
    let d = {}
    if ((this.company_filter?.value && this.company_filter?.value.length && this.clients_filter?.value && this.clients_filter?.value.length) || this.company_filter?.value && this.company_filter?.value.length && this.teacher_filter?.value && this.teacher_filter?.value.length) {
      if(this.teacher_filter?.value && this.teacher_filter?.value.length ){
        this.teacher_filter?.value.length == 1 ? d['type'] = 'Teacher' : d['type'] = 'Teachers'
  
        d['filtervalue'] = this.teacher_filter?.value
          .map((el) => this.teacherData?.filter((e: any) => e.id == el)[0].display_name)
          .join(", ");
  
        d['company_name'] = this.company_filter?.value
          .map((el) => this.companyDatas?.filter((e: any) => e.id == el)[0].display_name)
          .join(", ");
  
        return d
       
      }else{
       

        this.clients_filter?.value.length == 1 ? d['type'] = 'Client' : d['type'] = 'Clients'
  
        d['filtervalue'] = this.clients_filter?.value
          .map((el) => this.clientData?.filter((e: any) => e.id == el)[0].display_name)
          .join(", ");
  
        d['company_name'] = this.company_filter?.value
          .map((el) => this.companyDatas?.filter((e: any) => e.id == el)[0].display_name)
          .join(", ");
  
        return d
      }
    }
    if (this.teacher_filter?.value && this.teacher_filter?.value.length) {
      this.teacher_filter?.value.length == 1 ? d['type'] = 'Teacher' : d['type'] = 'Teachers'
      d['filtervalue'] = this.teacher_filter?.value
        .map((el) => this.teacherData?.filter((e: any) => e.id == el)[0].display_name)
        .join(", ");
      return d
    }
    if (this.clients_filter?.value && this.clients_filter?.value.length) {
      this.clients_filter?.value.length == 1 ? d['type'] = 'Client' : d['type'] = 'Clients'

      d['filtervalue'] = this.clients_filter?.value
        .map((el) => this.clientData?.filter((e: any) => e.id == el)[0].display_name)
        .join(", ");
      return d
    }
    
    if (this.company_filter?.value && this.company_filter?.value.length) {
      this.company_filter?.value.length == 1 ? d['type'] = 'Company' : d['type'] = 'Companies'

      d['filtervalue'] = this.company_filter?.value
        .map((el) => this.companyDatas?.filter((e: any) => e.id == el)[0].display_name)
        .join(", ");
      return d
    }
    if (this.industry_filter?.value && this.industry_filter?.value.length) {
      this.industry_filter?.value.length == 1 ? d['type'] = 'Industry' : d['type'] = 'Industries'

      d['filtervalue'] = this.industry_filter?.value
        .map((el) => this.industryData?.filter((e: any) => e.id == el)[0].name)
        .join(", ");
      return d
    }
    if (this.industry_type?.value && this.industry_type?.value.length) {
      this.industry_type?.value.length == 1 ? d['type'] = 'Category' : d['type'] = 'Categories'

      d['filtervalue'] = this.industry_type?.value
        .map((el) => this.industry_types?.filter((e: any) => e.id == el)[0].name)
        .join(", ");
      return d
    }




  }
  changeFilter(evt: any, type: any, u?: any) {
    this.placeholderText = ''
    if (evt?.type == 'change') {
      return
    }
    if (!this.resetAdvanceFilterCalled){this.resetAdvanceFilter(); this.resetAdvanceFilterCalled = true; }   
    if (type == 'Industy_type') {
      this.category = evt.length
      this.RefreshClientDropdown = true
      this.RefreshCompanyDropdown = true
      this.RefreshTeacherDropdown = true
      if (this.advancedData['Industry']) delete this.advancedData['Industry'];
      if (this.advancedData['Company']) delete this.advancedData['Company'];
      if (this.advancedData['Client']) delete this.advancedData['Client'];
      if (this.advancedData['Course']) delete this.advancedData['Course']
      this.industry_filter.reset()
      this.company_filter.reset();
      this.clients_filter.reset();
      this.teacher_filter.reset();
      this.courses_filter.reset();

      // for add category status filter
      
      // need to remove Prospect and Client ID 8,9 in this array (this.industry_type?.value)
      
      let filteredValues = this.industry_type?.value.filter(element => element !== 8 && element !== 9 && element !== 10 && element !== 11 && element !== 12);
      

      this.advancedData['Industry_type'] = filteredValues.toString();
      if (!this.industry_type?.value?.length) {
        delete this.advancedData['Industry_type'];
        this._cObsService.sendSearchValueDataForClientHealthReport('resetAllFilter');

      }
      this.searchFilterForm.value.company_name = null
      let data = []
      evt.forEach(element => {
        if (element.name == 'Client' || element.name == 'Prospect' || element.name == 'Pilot' || element.name == 'Former Client' || element.name == 'Partner') {
          data.push(element.name)
        }
      });
      this.getDropdownIndustry(data);
      data = []


      this.indus_type = {};
      if (this.advancedData['Industry']) this.indus_type = { id: this.advancedData['Industry_type'], role_id: 11, display_name: 'Industry_type' }
      this.onChange(this.indus_type);
    }
    else if (type == 'Industry') {
      this.industry = evt.length;
      this.RefreshClientDropdown = true
      this.RefreshCompanyDropdown = true
      this.RefreshTeacherDropdown = true

      if (this.advancedData['Company']) delete this.advancedData['Company'];
      if (this.advancedData['Client']) delete this.advancedData['Client'];
      if (this.advancedData['Course']) delete this.advancedData['Course']
      this.company_filter.reset();
      this.clients_filter.reset();
      this.teacher_filter.reset();
      this.courses_filter.reset();

      this.advancedData['Industry'] = this.industry_filter?.value?.toString();
      if (!this.industry_filter?.value?.length) {
        delete this.advancedData['Industry'];
        this._cObsService.sendSearchValueDataForClientHealthReport('resetAllFilter');

      }
      this.searchFilterForm.value.company_name = null
      this.getDropdownIndustry();
      this.indus_val = {};
      if (this.advancedData['Industry']) this.indus_val = { id: this.advancedData['Industry'], role_id: 7, display_name: 'Industry', type: this.advancedData['Industry_type'] }
      this.onChange(this.indus_val);




    }
    else if (type == 'Company') {
      this.selectCompany = evt.length
      this.RefreshClientDropdown = true
      this.RefreshTeacherDropdown = true
      delete this.advancedData['Client'];
      delete this.advancedData['Course'];
      this.clients_filter.reset();
      this.teacher_filter.reset();
      this.courses_filter.reset();
      this.advancedData['Company'] = this.company_filter.value.toString();

      if (!this.company_filter.value.length) {
        delete this.advancedData['Company']
        this._cObsService.sendSearchValueDataForClientHealthReport('resetAllFilter');
      }

      this.getDropdownIndustry();
      if (this.advancedData.Company == undefined && this.advancedData.Industry != undefined) {

        if (this.advancedData['Industry']) {
          this.indus_val = { id: this.advancedData['Industry'], role_id: 7, display_name: 'Industry' }
          this.onChange(this.indus_val)
        }
      }
      else {
        this.comp_val = {};

        if (this.advancedData['Company']) this.comp_val = { id: this.advancedData['Company'], role_id: 4, display_name: 'Company' }
        this.onChange(this.comp_val);

      }

    }
    else if (type == 'Client') {
      this.Client = evt.length
      this.RefreshTeacherDropdown = true
      delete this.advancedData['Course'];
      this.advancedData['Client'] = this.clients_filter.value.toString();
      this.teacher_filter.reset();
      this.courses_filter.reset();
      if (!this.clients_filter.value.length) {
        delete this.advancedData['Client']
        this._cObsService.sendSearchValueDataForClientHealthReport('resetAllFilter');
      }
      this.getDropdownIndustry();
      if (this.advancedData.Client == undefined && (this.advancedData.Company != undefined || this.advancedData.Industry != undefined || this.advancedData.Industry_type != undefined)) {

        if (this.advancedData['Company']) {

          this.comp_val = { id: this.advancedData['Company'], role_id: 4, display_name: 'Company' }
          this.onChange(this.comp_val);
        }
        else {
          this.indus_val = { id: this.advancedData['Industry'], role_id: 7, display_name: 'Industry' }
          this.onChange(this.indus_val);
        }
      }
      else {
        this.client_val = {};
        if (this.advancedData['Client']) this.client_val = { id: this.advancedData['Client'], role_id: 2, display_name: 'Client' }
        this.onChange(this.client_val);
      }

    }
    else if (type == 'Teacher') {
      this.Teacher = evt.length
      this.advancedData['Client'] = this.teacher_filter.value.toString();

      this.courses_filter.reset();
      if (!this.teacher_filter.value?.length) {
        delete this.advancedData['Teacher']
        if (this.clients_filter.value) {

          const ids = this.clients_filter.value?.map(e => e).join(', ');
          this.advancedData['Client'] = ids
          this.teacher_val = {};
          if (this.advancedData['Client']) this.teacher_val = { id: this.advancedData['Client'], role_id: 2, display_name: 'Client', is_teacher: 1 }
          this.onChange(this.teacher_val);
          this._cObsService.sendSearchValueDataForClientHealthReport('resetAllFilter');
        }


      }
      this.getDropdownIndustry();
      if (this.advancedData.Teacher == undefined && this.advancedData.Client == undefined && (this.advancedData.Company != undefined || this.advancedData.Industry != undefined || this.advancedData.Industry_type != undefined)) {

      }
      else {

        this.teacher_val = {};
        if (this.advancedData['Client']) this.teacher_val = { id: this.advancedData['Client'], role_id: 2, display_name: 'Client', istype: 1, is_teacher: 1 }
        if (this.clients_filter.value?.length && !this.teacher_filter.value?.length) delete this.teacher_val.istype
        this.onChange(this.teacher_val);
      }

    }


    else if (type == 'Course') {
      this.filtervalue.course = this.courses_filter.value.toString();
      this.advancedData['Course'] = this.courses_filter.value.toString();

      if ((this.advancedData.Course == undefined || !this.advancedData.Course) && (this.advancedData.Client != undefined || this.advancedData.Company != undefined || this.advancedData.Industry != undefined)) {
        if (this.advancedData['Client']) {
          this.client_val = { id: this.advancedData['Client'], role_id: 2, display_name: 'Client' }
          this.onChange(this.client_val);
        }
        else if (this.advancedData['Company']) {
          this.comp_val = { id: this.advancedData['Company'], role_id: 4, display_name: 'Company' }
          this.onChange(this.comp_val);
        }
        else {
          this.indus_val = { id: this.advancedData['Industry'], role_id: 7, display_name: 'Industry' }
          this.onChange(this.indus_val);
        }
      }
      else {
        this.course_val = {};
        if (this.advancedData['Course']) this.course_val = { id: this.advancedData['Course'], role_id: 9, display_name: 'Course' }
        this.onChange(this.course_val);
      }


    }
    else {

      this.getDropdownIndustry();
    }

  }
  comp_vals: any

  async childClick(child: any, parentName: any) {
    try {
    if (parentName == 'User') {
      this.placeholderText = ''
    } else {

      this.placeholderText = '(' + parentName + ')'
    }
    if (!this.resetAdvanceFilterCalled){this.resetAdvanceFilter(); this.resetAdvanceFilterCalled = true; }   
    this.industry_filter.reset();
    this.company_filter.reset();
    this.clients_filter.reset();
    this.teacher_filter.reset()
    this.courses_filter.reset();
    delete this.advancedData['Industry'];
    delete this.advancedData['Company'];
    delete this.advancedData['Client'];
    delete this.advancedData['Teacher']
    delete this.advancedData['Course']

    this.indus_val = {};
    this.client_val = { id: '', role_id: '', display_name: '', runEmpty: 'NO' }

    if(child.role_id == 1){ await this.getSelectedUserClassCode(child.id); }
    let id = child.id
    this.childclicked = false
    this.comp_vals = {
      user_id: child?.id,
      id: child.id,
      role_id: child.role_id,
      display_name: parentName,
      client_class_code: child.role_id == 1 ? this.selectedUserClassCode.client_class_code : child.client_class_code,
      client_id: child.client_id,
      company_id: child.company_id,
    };
    
    if (child?.parent_id) {
      this.comp_vals.parent_id = child?.parent_id
    }
    this.IsDisplayFilterd = false;

    await this.onChange(this.comp_vals);
    this.searchFilterForm.patchValue({ company_name: child.display_name });
    this.searchFilterValue = '';
    this.dat = [];
    if (parentName == 'Company') {
      this.company_filter.patchValue([child.id])
      this.advancedData['Company'] = child.id
      this.RefreshClientDropdown = true
      this.RefreshTeacherDropdown = true
      this.getDropdownIndustry()

    } else if (parentName == 'Client') {
      //  this.changeFilter(child1,'Company')
      this.RefreshClientDropdown = true
      this.clients_filter.patchValue([child.id])
      this.advancedData['Client'] = child.id
      this.RefreshTeacherDropdown = true
      setTimeout(() => {
        this.getDropdownIndustry()
      }, 500);

    } else if (parentName == 'Teacher') {
      this.teacher_filter.patchValue([child.id])
      this.advancedData['Client'] = child.id
    }
  } catch (error) {
      console.log(error);
  }
  }
  reset() {
    this.searchFilterForm.reset();
    this.display_value = '';
    this.stayUsers = [];
    this.users = [];
   
    this.searchall(this.display_value)
  }

  originalItems
  customFilter(value: string) {
    this.originalItems = this.clientData
    if (value) {
      this.clientData = this.originalItems.filter(item => item.display_name.toLowerCase().includes(value.toLowerCase()));
    } else {
      this.clientData = this.originalItems.slice(); // Reset to original items if search input is empty
    }
  }
  toggleSelectAll() {
    this.selectAllChecked = !this.selectAllChecked;

    this.onFilterCompany();
  }

  /* Client dorpdown data on scroll loading starts */
  bufferSize = 8000;
  clientInput$ = new Subject<string>();
  teacherInput$ = new Subject<string>()
  clientLoading = false;
  clientDataBuffer = [];
  teacherDataBuffer = []
  clientDataBuffer$: Observable<any>;
  teacherDataBuffer$: Observable<any>

  clearclients() {
    this.RefreshClientDropdown = true
  }
  fetchMoreClients(term) {
    const len = this.clientDataBuffer.length;
    if (term) {
      term = term.toLowerCase();
    } else {
      term = '';
    }
    const more = this.clientData.filter((x) => x.display_name.toLowerCase().includes(term)).slice(len, this.bufferSize + len);
    this.clientLoading = true;
    setTimeout(() => {
      this.clientLoading = false;
      this.clientDataBuffer = this.clientDataBuffer.concat(more);
      this.clientDataBuffer$ = of(this.clientDataBuffer);
    }, 200);
  }
  onFilterClient() {
    this.clientDataBuffer = this.clientData.slice(0, 10000);
    this.clientDataBuffer$ = of(this.clientDataBuffer);
    this.clientInput$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((term) => this.clientService(term))
      )
      .subscribe((data) => {
        this.clientDataBuffer = data.slice(0, this.bufferSize);
        this.clientDataBuffer$ = of(this.clientDataBuffer);
      });

  }
  onFilterTeacher() {
    this.teacherDataBuffer = this.teacherData.slice(0, 10000);
    this.teacherDataBuffer$ = of(this.teacherDataBuffer);
    this.teacherInput$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((term) => this.teacherService(term))
      )
      .subscribe((data) => {
        this.teacherDataBuffer = data.slice(0, this.bufferSize);
        this.teacherDataBuffer$ = of(this.teacherDataBuffer);
      });

  }

  private clientService(term) {
    if (!term) term = ''
    if (term) {
      term = term.toLowerCase();
    }
    return of(this.clientData).pipe(
      map((data) =>
        data.filter((x: { display_name: string }) =>
          x.display_name.toLowerCase().includes(term)
        )
      )
    );
  }

  private teacherService(term) {
    if (!term) term = ''
    if (term) {
      term = term.toLowerCase();
    }

    return of(this.teacherData).pipe(
      map((data) =>
        data.filter((x: { display_name: string }) =>
          x.display_name.toLowerCase().includes(term)
        )
      )
    );
  }


  /* Client dorpdown data on scroll loading ends */

  /* Company dorpdown data on scroll loading starts */

  companyInput$ = new Subject<string>();
  companyLoading = false;
  companyDatasBuffer = [];
  companyDatasBuffer$: Observable<any>;

  clearCompany() {
    this.RefreshCompanyDropdown = true
  }
  fetchMoreCompany(term) {
    const len = this.companyDatasBuffer.length;
    if (term) {
      term = term.toLowerCase();
    } else {
      term = '';
    }
    const more = this.companyDatas.filter((x) => x.display_name.toLowerCase().includes(term)).slice(len, this.bufferSize + len);
    this.companyLoading = true;
    setTimeout(() => {
      this.companyLoading = false;
      this.companyDatasBuffer = this.companyDatasBuffer.concat(more);
      this.companyDatasBuffer$ = of(this.companyDatasBuffer);
    }, 200);
  }

  onSelectedItemsChange(selectedItems: any): void {
  }
  onFilterIndustry() {
    this.industryDataBuffer = this.industryData.slice(0, 200);
    this.industryDataBuffer$ = of(this.industryDataBuffer);
    this.industryInput$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((term) => this.industryService(term))
      )
      .subscribe((data) => {
        this.industryDataBuffer = data.slice(0,200);
        this.industryDataBuffer$ = of(this.industryDataBuffer);
      });


  }
  private industryService(term) {
    if (!term) term = ''
    if (term) {
      term = term.toLowerCase();
    }

    return of(this.industryData).pipe(
      map((data) =>
        data.filter((x: { name: string }) =>
          x.name.toLowerCase().includes(term)
        )
      )
    );
  }

  onFilterCompany() {
    this.companyDatasBuffer = this.selectAllChecked ? this.companyDatas : this.companyDatas.slice(0, 10000);
    this.companyDatasBuffer$ = of(this.companyDatasBuffer);
    this.companyInput$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((term) => this.companyService(term))
      )
      .subscribe((data) => {
        this.companyDatasBuffer = data.slice(0, this.bufferSize);
        this.companyDatasBuffer$ = of(this.companyDatasBuffer);
      });
  }
  private companyService(term) {
    if (!term) term = ''
    if (term) {
      term = term.toLowerCase();
    }

    return of(this.companyDatas).pipe(
      map((data) =>
        data.filter((x: { display_name: string }) =>
          x.display_name.toLowerCase().includes(term)
        )
      )
    );
  }
  /* Company dorpdown data on scroll loading ends */

  /** company tooltip */
  get selectCompanyTooltip(): string {
    if (document.getElementsByClassName('company-tooltip-client')[0]?.classList.contains('ng-select-opened')) {
      return "";
    }

    if (this.company_filter?.value) {
      return this.company_filter?.value
        .map((el) => this.companyDatas?.filter((e: any) => e.id == el)[0].display_name)
        .join(", ");
    }
    else return "";
    
  }
  /** company tooltip */
  /**client tooltip */
  get selectClientTooltip(): string {
    if (document.getElementsByClassName('client-tooltip')[0]?.classList.contains('ng-select-opened')) {
      return "";
    }

    if (this.clients_filter?.value) {
      return this.clients_filter?.value
        .map((el) => this.clientData?.filter((e: any) => e.id == el)[0].display_name)
        .join(", ");
    }
    else return "";
  }
  /**client tooltip */

  /**industry tooltip */
  get selectIndustryTooltip(): string {
    if (document.getElementsByClassName('industry-tooltip-client')[0]?.classList.contains('ng-select-opened')) {
      return "";
    }

    if (this.industry_filter?.value) {
      return this.industry_filter?.value
        .map((el) => this.industryData?.filter((e: any) => e.id == el)[0].name)
        .join(", ");
    }
    else return "";
  }

  get selectIndustrTypeTooltip(): string {
    if (document.getElementsByClassName('industry-type-tooltip-client')[0]?.classList.contains('ng-select-opened')) {
      return "";
    }
    if (this.industry_type?.value) {
      return this.industry_type?.value
        .map((el) => this.industry_types?.filter((e: any) => e.id == el)[0].name)
        .join(", ");
    }
    else return "";
  }

  get selectTeacherTooltip(): string {
    if (document.getElementsByClassName('teacher-tooltip-client')[0]?.classList.contains('ng-select-opened')) {
      return "";
    }
    if (this.teacher_filter?.value) {
      return this.teacher_filter?.value
        .map((el) => this.teacherData?.filter((e: any) => e.id == el)[0].display_name)
        .join(", ");
    }
    else return "";
  }

  /**industry tooltip */
  sortingOfIndustry() {
    /**sorting logic of industy when click on dropdown items */
    this.industry_filter?.value
    if (this.industry_filter?.value.length != 0) {
      let sel = [],
        unsel = [];
      this.industryData.forEach((item) => {
        if (this.industry_filter?.value.indexOf(item?.id) !== -1) sel.push(item);
        else unsel.push(item);
      });

      this.industryData = [...sel, ...unsel];
    }
    else {
      this.industryData = this.indData
    }
  }

  /**sorting logic of company */
  sortingOfCompany() {
    this.company_filter.value
    if (this.company_filter.value.length !== 0) {
      this.companyDatasBuffer$ = this.companyDatasBuffer$.pipe(
        map((data) => {
          let sel = [];
          let unsel = [];
          data.forEach((item) => {
            if (this.company_filter.value?.indexOf(item?.id) !== -1) {
              sel.push(item);
            } else {
              unsel.push(item);
            }
          });
          return [...sel, ...unsel];
        })
      );
      this.companyDatasBuffer$.subscribe((updatedData) => {
      });
    } else {
      this.companyDatasBuffer$ = of(this.companyDatas);
    }
  }

  sortingOfClient() {
    this.clients_filter.value
    if (this.clients_filter.value.length !== 0) {
      this.clientDataBuffer$ = this.clientDataBuffer$.pipe(
        map((data) => {
          let sel = [];
          let unsel = [];
          data.forEach((item) => {
            if (this.clients_filter.value?.indexOf(item?.id) !== -1) {
              sel.push(item);
            } else {
              unsel.push(item);
            }
          });
          return [...sel, ...unsel];
        })
      );
      this.clientDataBuffer$.subscribe((updatedData) => {
      });
    } else {
      this.clientDataBuffer$ = of(this.clientData);
    }
  }

  onSearchClient(e: any) {
    if (!e) {
      setTimeout(() => {
        this.sortingOfClient()
      }, 1000);
    } else {

    }
  }
  onSearchIndustry(e: any) {
    if (!e) {
      setTimeout(() => {
        this.sortingOfIndustry()
      }, 1000);
    }
  }
  onSearchCompany(e: any) {

    if (!e) {
      setTimeout(() => {
        this.sortingOfCompany()
      }, 1000);

    }

  }
  clearInputField() {

    this.users = []; this.stayUsers = []; this.display_value = []; this.searchFilterForm.reset(); this.placeholderText = '';
    this.reset();
    this.IsDisplayFilterd = false;
    this.dat = []
    this.searchFilterForm.value.company_name = "";
    this.teacher_filter.reset()
    this.searchFilter(this.searchFilterForm.value.company_name);
    this._cObsService.sendSearchValueDataForClientHealthReport('resetAllFilter');
    if (!this.resetAdvanceFilterCalled){this.resetAdvanceFilter(); this.resetAdvanceFilterCalled = true; }   
  }
  focusInput(e: any) {
    this[e].nativeElement.focus();
  }
  hide
  hidetext(f: any) {
    this[f].nativeElement

  }
  customFilter1(term: string, item: any) {
    term = term.toLowerCase();
    return item.display_name.toLowerCase().includes(term);
  }
  selectAllChecked1 = false;
  updateSelectAll() {
    this.selectAllChecked1 = true
  }



  @ViewChild('reportrange', { static: false }) reportrange: any;
  private subscription: Subscription;
  ngAfterViewInit(): void {
    this.subscription = this._cObsService.getNavbarEvent().subscribe(data => {
      if (data) {
        this.resetAllFilter();
        this.onSelectRole('Select', 0)
        this.dateRangefunc();
      }
    });
   
  }
  dateRangefunc(){
    try {
      let datepicker2 = {...datepicker}
      datepicker2.ranges['Today'] = [this.today, this.today];
      if (typeof window !== 'undefined') {
        const start = moment().subtract(1,'year');
        const end = moment();
        const cb = (start: any, end: any) => {   
          this.selectedDate({ start, end })
        };
        // Initialize the daterangepicker directly using the library
        (window as any).$(this.reportrange.nativeElement).daterangepicker(datepicker2, cb);
        cb(start, end);
      }
    } catch (error) {
      console.log(error);

    }
  }  
  async modalOpenInfo(modalInfo, id, class_code) {
    try {
      this.modalService.open(modalInfo, { size: 'xl', scrollable: true, windowClass: 'modal featured-image-modal user-profile-modal ump-full-width' })
      this.cos.userid({ id: id, role_Id: 1, class_code: class_code });
    }
    catch (e) {

      this.toastr.warning('', environment.serverErrorMessage);
    }
  }

  async getSelectedUserClassCode(user_id: any): Promise<void> {
    let obj = { id: user_id };
    try {
      const res = await this.httpservice.post('getSelectedUserClassCode', obj).toPromise();
      if (res.status) {
        this.selectedUserClassCode = res.data[0];
      }
    } catch (error) {
      console.error('Error fetching user class code:', error);
    }
  }

}

