<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    © Copyright 2015 Enriched Academy | {{currentYear}} All Rights Reserved.
    
  </span> 
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
<div class="buy-now">
  <a href="https://1.envato.market/vuexy_admin" target="_blank" class="btn btn-danger" *ngIf="coreConfig.layout.buyNow"
    >Buy Now</a
  >
</div>
