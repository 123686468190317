import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { CoreCommonModule } from '@core/common.module';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';

import { NavbarComponent } from 'app/layout/components/navbar/navbar.component';
import { NavbarBookmarkComponent } from 'app/layout/components/navbar/navbar-bookmark/navbar-bookmark.component';
import { NavbarSearchComponent } from 'app/layout/components/navbar/navbar-search/navbar-search.component';
import { NavbarNotificationComponent } from 'app/layout/components/navbar/navbar-notification/navbar-notification.component';


import { IgxDateRangePickerModule } from "igniteui-angular";

import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { EnrichedAcademyNumberFormatterModule } from 'app/pipes/number-formatter/number-formatter.module';
import { UserProfileManagementModule } from 'app/pages/user-profile-management/user-profile-management.module';


@NgModule({
  declarations: [
    NavbarComponent,
    NavbarSearchComponent,
    NavbarBookmarkComponent,
    NavbarNotificationComponent
  ],
  imports: [RouterModule, NgbModule, CommonModule, CoreCommonModule, 
    UserProfileManagementModule,
     CoreTouchspinModule,
    IgxDateRangePickerModule,
     NgSelectModule],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA],

  exports: [NavbarComponent]
})
export class NavbarModule {}
