import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private storageEvent = new Subject<boolean>();

  constructor() {
    window.addEventListener('storage', (event) => {
      if (event.key === null) {
      // localStorage has been cleared
        this.storageEvent.next(true);
      }
    });
  }

  getStorageEvent() {
    return this.storageEvent.asObservable();
  }
}
